import React, { Component } from "react";
import { axiosPage } from '../../API';
import Showcase from "../versionTwo/Showcase";

class AboutComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            sectionDescription: "",
            aboutImage: "",
            aboutTitle: "",
            aboutDescription: "",
            aboutListingOne: "",
            aboutListingTwo: "",
            aboutBtnText: "",
            aboutBtnLink: "",
            aboutsData: [],
            showAboutBanner: false
        };
        this.getAboutData();
    }

    getAboutData() {
        axiosPage
            .get(`about`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showAboutBanner: true,
                    aboutImage: response.section.image.url,
                    aboutTitle: response.section.title,
                    aboutDescription: response.section.content,
                    aboutListingOne: response.section.listing,
                    aboutListingTwo: response.section.listing2,
                    aboutsData: response.posts
                },
                    console.log(this.state.data)

                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    render() {
        const prefix = '.0'
        const aboutData = this.state.aboutsData.map((about, index) => (
            <React.Fragment key={index}>
                {index % 2 === 0 ? (
                    <div className="row mt-50">
                        <div className="col-lg-6 col-md-6">
                            <div className="about-content mb-0">
                                <h3>{about.title}</h3>
                                <p>{about.description}</p>
                                {about.content && (
                                    <blockquote className="blockquote">
                                        <p className="mb-0">
                                            {about.content}
                                        </p>
                                    </blockquote>
                                )}
                                {about.listing ? (
                                    <ul>
                                        {about.listing.map((value, index) => (
                                            <li key={index}>
                                                <i class="fas fa-check-circle"></i>
                                                {value.add_list_content}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="img">
                                <img src={about.image.url} alt="img" />
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="row mt-50">
                            <div className="col-lg-6 col-md-6">
                                <div className="img">
                                    <img src={about.image.url} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="about-content">
                                    <h3>{about.title}</h3>
                                    <p>{about.description}</p>
                                    {about.blockQuote && (
                                        <blockquote className="blockquote">
                                            <p className="mb-0">
                                                {about.blockQuote}
                                            </p>
                                        </blockquote>
                                    )}
                                    {about.listing ? (
                                        <ul>
                                            {about.listing.map((value, index) => (
                                                <li key={index}>
                                                    <i class="fas fa-check-circle"></i>
                                                    {value.add_list_content}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
            </React.Fragment>
        ));
        return (
            <React.Fragment>
                {/* Start About Area */}
                {this.state.showAboutBanner && this.props.pageName === "aboutPage" &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section id="about" className={this.props.pageName === "aboutPage" ? 'about-area ptb-50' : "about-area ptb-100"}>
                    <div className="container">
                        {this.state.data.length !== '' ?
                            <div class="aboutSection">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="about-img">
                                            <img
                                                src={this.state.aboutImage}
                                                alt="about"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="about-content">
                                            {/* {this.state.about ? */}
                                            <div>
                                                <h3>{this.state.aboutTitle} </h3>
                                                <p>{this.state.aboutDescription} </p>
                                                <ul className="pull-left">
                                                    {this.state.aboutListingOne ? this.state.aboutListingOne.map((value, index) => (
                                                        <li key={index}>
                                                            <i class="fas fa-check-circle"></i>
                                                            {value}
                                                        </li>
                                                    )) : ''
                                                    }
                                                </ul>
                                                <ul class="pull-left">
                                                    {this.state.aboutListingTwo ? this.state.aboutListingTwo.map((value, index) => (
                                                        <li key={index}>
                                                            <i class="fas fa-check-circle"></i>
                                                            {value}
                                                        </li>
                                                    )) : ''
                                                    }
                                                </ul>
                                            </div>
                                            {/* ) : ''} */}
                                            {this.props.pageName === "homePage" ? <a
                                                href={this.props.aboutBtnLink}
                                                className="btn btn-primary"
                                            >
                                                {this.props.aboutBtnText}
                                            </a> : ''}
                                        </div>
                                    </div>
                                </div>
                                {this.props.pageName === "home" ? '' : aboutData}
                            </div>
                            : ""}
                    </div>
                </section>
                {/* End About Area */}
            </React.Fragment>
        );
    }
}


export default AboutComponent;
