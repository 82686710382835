import React, { Component } from "react";
import "react-modal-video/css/modal-video.min.css";
// import axios from "axios";
import {axiosPage} from '../../API'

class OurProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            services: [],
            description: "",
            
        };
        console.log(this.props, "props")
        this.getData();

    }
    componentDidMount() {
        // this.getData();
    }

    getData() {
        axiosPage
            .get(`our-process`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    title: response.title,
                    services: response.services,
                    description: response.description,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }



    render() {
        // const imageUrl = window.innerWidth >= 650 ? this.props.showcaseImage : '';
        return (
            <React.Fragment>
                <section className={this.props.className}>
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.title}</h3>
                            <p>{this.state.description}</p>
                        </div>
                        <div className="row">
                            {this.state.services && this.state.services.map((value, index) =>
                                <div className="col-lg-4 col-md-4 col" key={index}>
                                    <div className="service-box  ">
                                        <div className="services text-center ">
                                            <h4 className="ptb-10 bg-gray">{value.title}</h4>
                                            <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
                                        </div>
                                        <div className="pad20 ">{value.sub_content && value.sub_content.map((content, index2) => (
                                            <div key={index2}>
                                                <h6>{content.title}</h6>
                                                {content.description}
                                            </div>
                                        ))}</div>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default OurProcess;
