import React, { Component } from "react";
import PropTypes from "prop-types";

class BriefContact extends Component {
    constructor(state) {
        super(state);
        this.state = {
            data: '',
            sectionName: '',
            sectionTitle: '',
            sectionDescription: '',
            worksData: []
        };
    }

    render() {
        return (
            <section className={this.props.pageName === 'workPage'|| this.props.pageName === 'maintainenace' || this.props.pageName === 'development'||this.props.pageName === 'privacy'? "how-works-area" : "how-works-area bg-gray"}>
                <div className="ov-hide">
                    <div className="container">
                        <div className={this.props.pageName === 'privacy'?"section-title":"section-title mtb-50"}>
                            <h3>Got a Brief?</h3>
                            <p className="pb-20">{this.props.description}</p>
                            <a
                                                href='#contact'
                                                className="btn btn-primary" 
                                            >
                                                  Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
BriefContact.propTypes = {
    description: PropTypes.string,
};

//Default Props
BriefContact.defaultProps = {
    description: "Security of your website is as important as its design. Think about it.",
};

export default BriefContact;
