import React from 'react';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";

class WooCommerce extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            section1Title: '',
            section1Description: '',
            section1Image: "",
            section1LeftListing: [],
            section1RightListing: [],
            section1Logo: '',
            section2BgImg: "",
            section2Img: "",
            section2Title: "",
            section3Title: "",
            section3LftListing: [],
            section3RghtListing: [],
            section3Img: "",
            section4Title: "",
            section4Services: [],
            section4Description: "",
            section5Title: "",
            section5Services: [],
            section5Description: "",
            showBanner: false

        };
        this.getData();

    }

    getData() {
        axiosPage
            .get(`woocommerce`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionTwo = response.section2;
                const sectionThree = response.section3;
                const sectionFour = response.section4;
                const sectionFive = response.section5;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section1Title: sectionOne.title,
                    section1Description: sectionOne.description,
                    section1Image: sectionOne.image ? sectionOne.image.url : "",
                    section1LeftListing: sectionOne.left_listing,
                    section1RightListing: sectionOne.right_listing,
                    section1Logo: sectionOne.logo ? sectionOne.logo.url : "",
                    section2BgImg: sectionTwo.background_image ? sectionTwo.background_image.url : '',
                    section2Img: sectionTwo.image ? sectionTwo.image.url : "",
                    section2Title: sectionTwo.title,
                    section3Title: sectionThree.title,
                    section3LftListing: sectionThree.left_listing,
                    section3RghtListing: sectionThree.right_listing,
                    section3Img: sectionThree.image ? sectionThree.image.url : '',
                    section4Title: sectionFour.title,
                    section4Services: sectionFour.services,
                    section4Description: sectionFour.description,
                    section5Title: sectionFive.title,
                    section5Services: sectionFive.services,
                    section5Description: sectionFive.description,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const section1 = <React.Fragment >
            <div className="bg-gray ov-hide">
                <div className="container">
                    <div className="row mtb-50">
                        <div className="col-lg-6 col-md-6">
                            <div className="img">
                                <img src={this.state.section1Image} alt="img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="about-text mb-0">
                                <h1><img src={this.state.section1Logo} alt="logo" />{" " + this.state.section1Title}</h1>
                                <p className="mb-30">{this.state.section1Description}</p>
                                <ul className="col-lg-6 col-md-6">{this.state.section1LeftListing && (this.state.section1LeftListing.map((list, index) => (
                                    <li key={index}>
                                        <i className="icofont-ui-check" />
                                        {list.l_listing}
                                    </li>
                                )))
                                }
                                </ul>
                                <ul className="col-lg-6 col-md-6">{this.state.section1RightListing && (this.state.section1RightListing.map((list, index) => (
                                    <li key={index}>
                                        <i className="icofont-ui-check" />
                                        {list.r_listing}
                                    </li>
                                )))
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
        const section2 = <div className="wooCommerce2 ">
            <div className="container ptb-50">
                <div className="section-title">
                    <h3 className="text-white">{this.state.section2Title}</h3>
                    <img src={this.state.section2Img} alt="img" />
                </div>
            </div>
        </div>
        const section3 = <div className="container ptb-50">
            <div className="section-title">
                <h3 >{this.state.section3Title}</h3>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                    <div className="img">
                        <img src={this.state.section3Img} alt="img" />
                    </div>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-6 col-xs-6">
                    <div className="about-text iconalign mb-0">
                        {this.state.section3LftListing ? <ul className="pull-left">
                            {this.state.section3LftListing.map((value, index) => (
                                <li key={index}>
                                    <i className="icofont-curved-right"></i>
                                    <div className="lftmrgn-20">{value.l_listing}</div>
                                </li>
                            ))}
                        </ul> : ''}
                    </div>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-6 col-xs-6">
                    <div className="about-text iconalign mb-0">
                        {this.state.section3RghtListing ? <ul className="pull-left">
                            {this.state.section3RghtListing.map((value, index) => (
                                <li key={index}>
                                    <i className="icofont-curved-right"></i>
                                    <div className="lftmrgn-20">{value.r_listing}</div>
                                </li>
                            ))}
                        </ul> : ''}
                    </div>
                </div>
            </div>
        </div>

        const section4 = <div className="bg-gray subscribe-area"><div className="container ptb-50 ">
            <div>
                <h1>{this.state.section4Title}</h1>
                <p> {this.state.section4Description}</p>
            </div>
            <div className="row ">
                {this.state.section4Services && (this.state.section4Services.map((value, index) => (
                    <div className="col" key={index}>
                        <div className="text-center">
                                <img src={value.image ? value.image.url : ''} alt="img" width="100" height="100" />
                            <h6 className="mt-20 text-white">{value.title}</h6>
                        </div>
                    </div>
                )))};
            </div>
        </div>
        </div>
        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <div>
                    {section1}
                    {section2}
                    {section3}
                    {section4}
                    <Testimonial pageName="home" />
                </div>
                {/* : ''} */}
            </React.Fragment>
        );
    }
}

export default WooCommerce;