import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import Subscribe from '../components/common/Subscribe';
import GetStartedForm from '../components/common/GetStartedForm';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";

class Consultancy extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            section3Col1Title: "",
            section3Col1Description: "",
            section3Col1Services: [],
            section3Col2Title: "",
            section3Col2Description: "",
            section3Col2Listing: [],
			pwaFeatures: [],
            showConsultancyBanner:false
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`consultancy`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionThree = response.section3;
               
                this.setState({ 
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showConsultancyBanner:true,
                    section3Col1Title: sectionThree.left.title,
                    section3Col1Description: sectionThree.left.description,
                    section3Col1Services:sectionThree.left.services,
                    section3Col2Title: sectionThree.right.title,
                    section3Col2Description: sectionThree.right.description,
                    section3Col2Listing:sectionThree.right.listing,
					pwaFeatures: response.features,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
   
    render() {
		const pwaFeatures = this.state.pwaFeatures ? this.state.pwaFeatures.map((feature, index) => (
			<React.Fragment key={index}>
				{index % 2 === 0 ? (
					<div className="textSection">
						<div className="container">
							<div className="row mtb-50">
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="textSection">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)) : '';
        const servicedata = this.state.section3Col1Services && (this.state.section3Col1Services.map((service, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="service-box text-center">
                    <div className="service-box-pic">
                        <img src={service.image && service.image.url} alt="serviceimage" width="80" height="80"/>
                    </div>
                    <h6>{service.title}</h6>
                </div>
            </div>
        )));
        const sectionThree =
		<section id="services" class="services-area ptb-50">
            <div className="container">
                <div className="section-title">
					<h3>{this.state.section3Col1Title}</h3>
					<p>{this.state.section3Col1Description}</p>
				</div>
				<div className="row">{servicedata}</div>
            </div>
        </section>
     
        return (
            <React.Fragment >
               {this.state.showConsultancyBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
				{pwaFeatures}
                {sectionThree}
                <GetStartedForm />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />   
            </React.Fragment>
        );
    }
}

export default Consultancy;