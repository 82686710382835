import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { axiosPage } from '../../API';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            sectionOneTitle: "",
            sectionOneDescription: "",
            sectionTwoTitle: "",
            sectionTwoMenu: [],
            sectionThreeTitle: "",
            sectionThreeMenu: [],
            sectionFourTitle: "",
            sectionFourDescription: "",
            sectionFourEmail: "",
            sectionFourPhone: "",
            sectionFourAddress: "",
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`footer`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const section1 = response.section1;
                const section2 = response.section2;
                const section3 = response.section3;
                const section4 = response.section4;
                this.setState({
                    data: response,
                    sectionOneTitle: section1.title,
                    sectionOneDescription: section1.description,
                    sectionTwoTitle: section2.title,
                    sectionTwoMenu: section2.menu,
                    sectionThreeTitle: section3.title,
                    sectionThreeMenu: section3.menu,
                    sectionFourTitle: section4.title,
                    sectionFourDescription: section4.description,
                    sectionFourEmail: section4.email,
                    sectionFourPhone: section4.phone,
                    sectionFourAddress: section4.address
                });
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        const script = document.createElement("script");

        script.src = "//code.tidio.co/3qlnz8u5qjhqx7uk2yuqbek4psin3zfn.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        const sectionTwoMenu =
            this.state.sectionTwoMenu ? this.state.sectionTwoMenu.map((support, index) => (
                <li key={index}>
                    <NavLink
                        to={support.url}
                        activeClassName=""
                    >
                        <i className="icofont-double-right" />
                        {support.title}
                    </NavLink>
                </li>
            )) : '';
        const sectionThreeMenu = this.state.sectionThreeMenu ? this.state.sectionThreeMenu.map((menu, index) => (
            <li key={index}>
                <NavLink
                    to={menu.url}
                    activeClassName=""
                >
                    <i className="icofont-double-right" />
                    {menu.title}
                </NavLink>
            </li>
        )) : '';

        return (
            <React.Fragment>
                <footer className="footer-area ptb-50">
                    <div className="container">
                        {this.state.data !== '' ?
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer">
                                        <h4 className="logo">
                                            <a href="#home">
                                                Totally<span> Outsourcing</span>
                                            </a>
                                        </h4>
                                        <p>{this.state.sectionOneDescription}</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer footer-navbar-nav">
                                        <h3>{this.state.sectionTwoTitle}</h3>
                                        <ul>{sectionTwoMenu}</ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer">
                                        <h3>{this.state.sectionThreeTitle}</h3>
                                        <ul>{sectionThreeMenu}</ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="single-footer contact-footer">
                                        <h3>{this.state.sectionFourTitle}</h3>
                                        <ul className="contact-info">
                                            <li>
                                                <i className="icofont-google-map" />
                                                {this.state.sectionFourAddress}
                                            </li>
                                            <li>
                                                <i className="icofont-phone" />
                                                {this.state.sectionFourPhone}
                                            </li>
                                            <li>
                                                <i className="icofont-envelope" />
                                                {this.state.sectionFourEmail}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </div>
                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <p>{this.props.copyRightText}</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    
                            </div>
                        </div>
                    </div>
                    </div>
                </footer>
            </React.Fragment >
        );
    }
}

//Default Props
Footer.defaultProps = {
    copyRightText: "\u00a9 Copyright 2020 All Rights Reserved.",

};
export default Footer;
