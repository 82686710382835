import React, { Component } from "react";
import { Form, Button, Col } from 'react-bootstrap';
import axios from 'axios';
import qs from 'querystring';
import PropTypes from "prop-types";

class GetStartedForm extends Component {
    constructor(state) {
        super(state);
        this.state = {
            name: '',
            email: '',
            phone: '',
            company: "",
            message: '',
            errorMessage: '',
            successMessage: ''
        };
    }
    postData(e) {
        console.log(this.props, "props")
        let url;
        const data = {
            "name": this.state.name,
            'email': this.state.email,
            "phone": this.state.phone,
            "company": this.state.company,
        };
        if (this.props.pageName === "Hire") {
            url = "https://outsourcing.m2agency.co.uk/wp-json/v1/request_to_call_back";
            data["message"]=this.state.message;
            // this.props.submit(this.state)
        } else {
            url = 'https://outsourcing.m2agency.co.uk/wp-json/v1/get_start';
        }
        axios
            .post(url, qs.stringify(data), { headers: { Accept: "application/json" } })
            .then(result => {
                const response = result.data;
                if (response) {
                    if (response.status === "success") {
                        this.setState({
                            email: "",
                            status: "success",
                            name: '',
                            phone: '',
                            company: "",
                            message:'',
                            successMessage: response.message,

                        });
                    } else {
                        this.setState({
                            status: "error",
                            errorMessage: response.message
                        });
                    }
                }
            })
            .catch(error => {
                console.log('error:', error)
                this.setState({
                    status: "error",
                    errorMessage: "OOPS, something went wrong. We are working on this.",
                    name: '',
                    email: '',
                    phone: '',
                    company: "",
                    message: "",
                    isLoading: false
                })
            }
            );
}
handleFields = e => this.setState({ [e.target.name]: e.target.value });

render() {
    let title;
    if (this.props.pageName === 'Hire') {
        title = "Request To Call Back"
    } else {
        title = "Ready to get started?"
    }
    return (
        <React.Fragment>
            <div className="requestCallSection">
                <div className="container">
                    <div className="section-title">
                        <h3><strong>Request</strong> To Call Back</h3>
                        {this.props.pageName !== 'Hire' && <p className="mb-30">{this.props.description}</p>}
                    </div>
                    <Form className="getStartedForm" onSubmit={(e, formData, inputs) => {
                        e.preventDefault();
                        this.postData(formData);
                    }}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridName">
                                <Form.Control type="text" placeholder="Name *" name="name" required onChange={this.handleFields} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Control type="email" placeholder="Email *" name="email" onChange={this.handleFields} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPhone">
                                <Form.Control placeholder="Phone *" type="text" name="phone" onChange={this.handleFields} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridCompany">
                                <Form.Control type="text" placeholder="Company *" name="company" onChange={this.handleFields} />
                            </Form.Group>
                        </Form.Row>
                        {this.props.pageName === 'Hire' && <Form.Row>
                            <Form.Group as={Col} controlId="formGridMessage">
								<Form.Control as="textarea" rows="4" placeholder="Message *" name="message" onChange={this.handleFields} />
							</Form.Group>
                        </Form.Row>}
                        <div className="submit mt-20">
                            <Button variant="primary" size="lg" type="submit" >
                                Send
                            </Button>
                        </div>
                    </Form>
                    {this.state.successMessage !== "" ? (
                        <h4 className="contactMsg alignCenter">
                            {this.state.successMessage}
                        </h4>
                    ) : <h4 className="contactErrorMsg alignCenter">
                            {this.state.errorMessage}
                        </h4>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}
}

GetStartedForm.propTypes = {
    description: PropTypes.string,
};

//Default Props
GetStartedForm.defaultProps = {
    description: "Get in touch to know how we can help you.",
};


export default GetStartedForm;
