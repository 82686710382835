import React from 'react';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";

class DigitalMarketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner: false,
            section1: [],

        };
        this.getData();

    }

    getData() {
        axiosPage
            .get(`digital_marketing_seo`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const section1 = response.section1;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section1Image: section1.image ? section1.image.url : '',
                    section1Title: section1.title,
                    section1Description: section1.description,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const section1 = <div className="row">
            <div className="col-lg-6 col-md-6 pic">
                <div className="img">
                    <img src={this.state.section1Image} alt="sectionImage" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 info">
				<h3>{this.state.section1Title}</h3>
				<p>{this.state.section1Description}</p>                
            </div>
        </div>

        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section className="textSection">
                    <div className="container">
                        {section1}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default DigitalMarketing;