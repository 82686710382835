import React from 'react';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";
import GetStartedForm from "../components/common/GetStartedForm";
import Testimonial from "../components/versionTwo/Testimonial";
import Industries from "../components/common/Industries";
import OurProcess from "../components/common/OurProcess";
class ReactNative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner: false,
            section1Title: "",
            section1Description: "",
            section1Image: "",
            section2Title: "",
            section2Image: "",
            section2Listing: [],
            section3Title: "",
            section3Services: [],
            section4Title: "",
            section4Services: [],
            section5Title: "",
            section5Services: [],
        };
        this.getData();

    }

    getData() {
        axiosPage
            .get(`react-native`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const section1 = response.section1;
                const section2 = response.section2;
                const section3 = response.section3;
                const section4 = response.section4;
                const section5 = response.section5;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section1Title: section1.title,
                    section1Description: section1.description,
                    section1Image: section1.image ? section1.image.url : '',
                    section2Title: section2.title,
                    section2Image: section2.image.url,
                    section2Listing: section2.listing,
                    section3Title: section3.title,
                    section3Services: section3.services,
                    section4Title: section4.title,
                    section4Services: section4.services,
                    section5Title: section5.title,
                    section5Services: section5.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const section1 = <div className="row mtb-50">
            <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                    <img src={this.state.section1Image} alt="sectionImage" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                    <h1>{this.state.section1Title}</h1>
                    <p className="mb-30">{this.state.section1Description}</p>

                </div>
            </div>
        </div>
        const section2 = <div className="row mtb-50">
            <div className="col-lg-6 col-md-6 about-text">
                <ul >
                    {this.state.section2Listing && this.state.section2Listing.map((value, index) => (
                        <li key={index}>
                            <i className="icofont-ui-check" />
                            {value.add_list}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                    <img src={this.state.section2Image} alt="sectionImage" />
                </div>
            </div>
        </div>
        const section3 = <div className="row ">
            {this.state.section3Services && this.state.section3Services.map((value, index) =>
                <div className="col-lg-3 col-md-3 col" key={index}>
                    <div className=" text-center pb-20">
                        <div className="services ">
                            <img src={value.image.url} alt="serviceimage" width="60" height="60" />
                            <h6>{value.title}</h6>
                        </div>
                        <div>
                            {value.description ? value.description : ""}</div>
                    </div>
                </div>)}
        </div>
        const section4 = <div className="row ">
            {this.state.section4Services && this.state.section4Services.map((value, index) =>
                <div className="col-lg-4 col-md-4 col" key={index}>
                    <div className="service-box text-center pb-20">
                        <div className="services bg-gray pad10">
                            <img src={value.image.url} alt="serviceimage" width="60" height="60" />
                        </div>
                        <div>
                            <h6>{value.title}</h6>
                            {value.description ? value.description : ""}
                        </div>
                    </div>
                </div>)}
        </div>
        const section5 = <div className="row">
            {this.state.section5Services && this.state.section5Services.map((value, index) =>
                <div className="col-lg-4 col-md-4 col pad20" key={index}>
                    <div className=" service ">
                        <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
                        <h6 className="service-content">{value.title}</h6>
                    </div>
                </div>
            )}
        </div>
        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section className="ov-hide bg-gray">
                    <div className="container">
                        {section1}
                    </div>
                </section>
                <section className="ov-hide">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section2Title}</h3>
                        </div>
                        {section2}
                    </div>
                </section>
                <section className="ov-hide bg-gray">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section3Title}</h3>
                        </div>
                        {section3}
                    </div>
                </section>
                <section className="ov-hide ">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section4Title}</h3>
                        </div>
                        {section4}
                    </div>
                </section>
                <section className="ov-hide bg-gray">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section5Title}</h3>
                        </div>
                        {section5}
                    </div>
                </section>
                <OurProcess className="ov-hide" />
                <Industries />
                <Testimonial pageName="bigcommerce" />
                <GetStartedForm pageName="Hire" />
            </React.Fragment>
        );
    }
}

export default ReactNative;