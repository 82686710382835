import React, { Component } from "react";
import { axiosPage } from '../../API';
import Showcase from "../versionTwo/Showcase";

class Works extends Component {
    constructor(state) {
        super(state);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            sectionTitle: '',
            sectionDescription: '',
            worksData: [],
            showBanner:false
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`howitworks`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner:true,
                    sectionTitle: response.sectionTitle,
                    sectionDescription: response.sectionDescription,
                    worksData: response.worksData
                },
                    console.log(this.state.data)
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    render() {
        const prefix = '0'
        //Works Data Loop Start
        const workdays = this.state.worksData ? this.state.worksData.map((work, index) => (
            <div
                className={
                    (index + 1) % 3 === 0
                        ? "col-lg-4 col-md-6 offset-lg-0 offset-md-3"
                        : "col-lg-4 col-md-6"
                }
                key={index}
            >
                <div
                    className={
                        (index + 1) % 3 === 0
                            ? "single-work alignCenter"
                            : "single-work with-line alignCenter"
                    }
                >
                    {/* <span>{prefix}{index + 1}</span> */}
                    <div className="alignCenter mb-30"><img src={work.image && work.image.url} alt="workimage"/></div>
                    <h3 ><strong>{prefix}{index + 1}</strong>  {work.title}</h3>
                    <p>{work.description}</p>
                </div>
            </div>
        )) : "";
        //Works Data Loop END

        return (
            <React.Fragment>
                             {this.state.showBanner && this.props.pageName ==="workPage" && 
    (<Showcase
        showcaseImage={this.state.showcaseImage}
        showcaseName={this.state.showcaseName}
        showcaseDescription={this.state.showcaseDescription} />)
}
                <section className={this.state.pageName === 'workPage' ? "how-works-area" : "stepWork"}>
                    <div className="container workspage">
                        {this.state.data !== 'null' ?
                            <div>
                                <div className="section-title">
                                    <h3><strong>Steps</strong> of Work</h3>
                                    <p>{this.state.sectionDescription}</p>
                                </div>

                                <div className="row">{workdays}</div>
                            </div> : ''}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Works;
