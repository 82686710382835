import React from 'react';
// import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { axiosPage } from '../API';
import Showcase from "../components/versionTwo/Showcase";

class Faq extends React.Component {
    constructor(props) {
        super(props);
        // 
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
          faq:[],
            showBanner:false
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`faq`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
               
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner:true,
                    faq:response.faq,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                  {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section className="faq-area ptb-50 pb-0">
                    <div className="container">
                        <Accordion>
                            {this.state.faq && this.state.faq.map((value,index)=>(
                                <AccordionItem key={index}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                       {value.ques}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        {value.ans}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            ))}
                            
                        </Accordion>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Faq;

