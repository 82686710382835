import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-modal-video/css/modal-video.min.css";
// import axios from "axios";
// import API from '../../API'

class Showcase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            data:''
        };
        this.openModal = this.openModal.bind(this);
        console.log(this.props,"props")
        
    }
    componentDidMount() {
        // this.getData();
    }
    

    openModal() {
        this.setState({ isOpen: true });
    }

    render() {
        // const imageUrl = window.innerWidth >= 650 ? this.props.showcaseImage : '';
        var homepage= '';
        if(this.props.showcaseHomeClass === 'Homepage-banner'){ 
            homepage = ' Homepage-banner';
        }
        return (
            <React.Fragment>
                <div id="home" className={"main-banner" + homepage } style={{backgroundImage: `url(${this.props.showcaseImage})` }}>
                    {/* <div className={this.props.pageName==='pwa'?"creative-bg":"creative-homebg"}> */}
                    {/* <img
                                                src={this.props.showcaseImage}
                                                alt="img"
                                            /> */}
                        {/* </div> */}
                    {/* <div className={this.props.pageName==='pwa'?"":"pattern"} /> */}
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="main-banner-content">
                                            <h1 class="1"><strong>{this.props.showcaseName}</strong>{this.props.showcaseDescription}{this.props.showcaseNameLine2}</h1>
                                            {/* <p>
                                                {this.props.showcaseDescription}
                                            </p> */}
                                            {this.props.showcaseBtnOneText && <a
                                                href={
                                                    this.props
                                                        .showcaseBtnOneLink
                                                }
                                                className="btn btn-antique"
                                            >
                                                {this.props.showcaseBtnOneText}
                                            </a>}
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col-md-6">
                                        <div className="banner-img">
                                            <img height="100%" width="100%"
                                                 src={this.props.showcaseImage}
                                                alt="img"
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-bottom" /> */}
                </div>
            </React.Fragment>
        );
    }
}

//Props Types
Showcase.propTypes = {
    showcaseHomeClass: PropTypes.string,
    showcaseName: PropTypes.string,
    showcaseNameLine2: PropTypes.string,
    showcaseDescription: PropTypes.string,
    showcaseImage: PropTypes.string,
    showcaseBtnOneLink: PropTypes.string,
    showcaseBtnOneText: PropTypes.string,
};

//Default Props
Showcase.defaultProps = {
    showcaseName: "Make Your Business",
    showcaseNameLine2: "",
    showcaseDescription:
        "More Profitable",
    showcaseImage: require("../../assets/img/mainBanner.jpg"),
    showcaseHomeClass: '',
    // showcaseBtnOneLink: "#about",
    // showcaseBtnOneText: "Get Started",
};

export default Showcase;
