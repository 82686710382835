import React, { Component } from "react";
import "react-modal-video/css/modal-video.min.css";
// import axios from "axios";
import {axiosPage} from '../../API'

class Industries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            services: [],
        };
        this.getData();
    }
    componentDidMount() {
        // 
    }
    getData() {
        axiosPage
            .get(`industries`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    title: response.title,
                    services: response.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }


    render() {
        return (
            <React.Fragment>
                <section className="ov-hide bg-gray">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.title}</h3>
                        </div>
                        <div className="row">
                            {this.state.services && this.state.services.map((value, index) =>
                                <div className="col-lg-3 col-md-3 col" key={index}>
                                    <div className=" text-center ">
                                        <div className="services pad20">
                                            <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
                                        </div>
                                        <div>{value.title}</div>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Industries;
