import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import { axiosPage } from '../../API';

class Testimonial extends Component {
    // testimonials
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            sectionName: "",
            sectionTitle: "",
            sectionDescription: "",
            testimonialsData: []
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`testimonials`)
            .then(result => {
                const response = result.data;
                this.setState({
                    testimonialsData:response.testimonials,
                    sectionName: response.heading,
                    sectionTitle: response.title,
                    sectionDescription: response.description,
                    // testimonialsData: response.testimonials,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    render() {
        //Testimonial Data loop Start
        const testimonialData = this.state.testimonialsData.length && (this.state.testimonialsData.map(
            (testimonial, index) => (
                <div className="col-lg-12 col-md-12" key={index}>
                    <div className="testimonials-item">                        
                        <p>{testimonial.description}</p>
						<div className="client-info">
                            <div className="client-title">
                                <h4>{testimonial.title}</h4>
                            </div>
                        </div>
                        <i className="icofont-quote-left" />
                    </div>
                </div>
            ))
        );
        //Testimonial Data loop END
        return (
            <React.Fragment>
                <section className={this.props.pageName === "development"||this.props.pageName === "home" ? "testimonials-area" : "testimonials-area  ptb-50 "}>
                    <div className="container">
                        <div className="section-title">
                            <h3><strong>The</strong> Testimonials</h3>
                        </div>
                        <div className="row">
                            <OwlCarousel
                                className="owl-theme testimonials-slides"
                                loop
                                autoplay={true}
                                nav={false}
                                mouseDrag={true}
                                autoplayHoverPause={true}
                                responsiveClass={true}
                                dots={true}
                                navText={[
                                    "<i class='icofont-curved-double-left'></i>",
                                    "<i class='icofont-curved-double-right'></i>"
                                ]}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    768: {
                                        items: 2
                                    },
                                    1024: {
                                        items: 2
                                    },
                                    1200: {
                                        items: 2
                                    }
                                }}
                            >
                                {testimonialData}
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonial;
