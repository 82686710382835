import React, { Component } from "react";
import Subscribe from "../components/common/Subscribe";
import ContactForm from "../components/common/ContactForm";
import Showcase from "../components/versionTwo/Showcase";
import { axiosPage } from '../API';
// import GoogleMap from "../components/common/GoogleMap";

class ContactUS extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    constructor(props) {
        super(props);
        this.state = {
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner: false,
            sectionOne: "",
            sectionTwo: "",
            contactDescriptionsTwo: ""
        };
        this.getData();
    }
    getData() {
        axiosPage
            .get(`contact`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionTwo = response.section2;
                this.setState({
                    data: response,
                    showcaseName: response.title ? response.title : '',
                    showcaseDescription: response.description ? response.description : '',
                    showcaseImage: response.image ? response.image.url : '',
                    showBanner: true,
                    sectionOne: sectionOne,
                    sectionTwo: sectionTwo,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {/* Start Page Title Area */}
                    {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription}
                         />)
                }
                    {/* End Page Title Area */}
                    {/* Start Contact Area */}
                    <section className="contact-area mt30 pt-50">
                        <div className="">
                        <div className="container">
                            <div className="row">
                                {this.state.sectionOne && this.state.sectionOne.map((value,index)=>
                                <div className="col-lg-4 col-md-12">
                                    <div className="contact-box">
                                        <div className="icon">
                                            <i className={value.icon} />
                                        </div>
                                        <div className="content">
                                            <h4>{value.title}</h4>
                                            <p>
                                                <a href={value.url}>
                                                {value.content}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                 )}
                            
                                {/* <div className="col-lg-12 col-md-12">
                                    <div id="map">
                                        <GoogleMap />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        </div>
                        <div className="ptb-50 bg-gray">
                            <div className="container">
                                <div className="mt-50 row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-text">
                                            <h3>{this.state.sectionTwo.title}</h3>
                                            <p>
                                                {this.state.sectionTwo.description}
                                            </p>
                                            <p>
                                                {this.props.contactDescriptionsTwo}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 " id="contactForm">
                                        <ContactForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Start Contact Area */}
                </div>
                {/* Subscribe: src/components/common/Subscribe */}
                <Subscribe />
            </React.Fragment>
        );
    }
}

//Props Types
ContactUS.propTypes = {
    contactDescriptionsTwo: ""
};
//Default Props
ContactUS.defaultProps = {
    contactDescriptionsTwo:
        " Experience the most flawless and exceptional outsourcing service."
};

export default ContactUS;
