import React, { Component } from "react";
import axios from 'axios';
import qs from 'querystring';

class Subscribe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            status: '',
            message: '',
            error: '',
            customMessage: "Please enter valid Email",

        };
        this.email = React.createRef();
        this.subscribeEmail = this.subscribeEmail.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }
    handleNameChange(e) {
        e.preventDefault();
        this.setState({ email: e.target.value });
    }

    subscribeEmail(e) {
        e.preventDefault();
        let email = this.state.email;
        this.setState({ status: 'sending' });
        if (email && email.indexOf("@") > -1) {
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const data = {
                email: email
            };
            axios
                .post('https://outsourcing.m2agency.co.uk/wp-json/v1/subscribe', qs.stringify(data), config)
                .then(result => {
                    const response = result.data;
                    if (response) {
                        if (response.status === "success") {
                            this.setState({
                                email: "",
                                status: "success",
                                message: response.message,

                            });
                        } else {
                            this.setState({
                                status: "error",
                                message: response.message
                            });
                        }
                    }
                })
                .catch(error => {
                    console.log('error:', error)
                    this.setState({
                        status: "error",
                        message: "OOPS, something went wrong. We are working on this.",
                        email: '',
                        isLoading: false
                    })
                }
                );

        } else {
            this.setState({ status: 'error1' })
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="subscribe-area">
                    {/* <div className="subscribeImage">
                    <img
                                                src={subscribeImage}
                                                alt="img"
                                            />
                    </div> */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div >
                                    <h3><strong>Subscribe</strong> to Our Updates</h3>
                                    <p>The latest digital workplaces news, events, guides and case studies.</p>                                  
									<div className="subscribe-form form">
										<input className="input-group-field form-control" type="email" placeholder="Enter your Email" value={this.state.email}
											onChange={this.handleNameChange} ref={(input) => this.email = input}
											required />
										<button className="btn btn-primary" onClick={this.subscribeEmail}>Subscribe</button>

										{this.state.status === "sending" && (
											<div className="subs-sending-msg">sending...</div>
										)}
										{this.state.status === "error" && (
											<div
												className="subs-error-msg"
												dangerouslySetInnerHTML={{ __html: this.state.message }}
											/>
										)}
										{this.state.status === "error1" && (
											<div
												className="subs-error-msg"
											>{this.state.customMessage}</div>
										)}
										{this.state.status === "success" && (
											<div
												className="subs-success-msg"
												dangerouslySetInnerHTML={{ __html: this.state.message }}
											/>
										)}
									</div>
                                    <h5>Totally Outsourcing uses your information to contact you about our products and services . You may unsubscribe from these communications at anytime.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Subscribe;
