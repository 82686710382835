import React, { Component } from "react";
import { axiosPage } from '../../API';
import { LinkContainer } from "react-router-bootstrap";

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            sectionName: "",
            sectionTitle: "",
            sectionDescription: "",
            servicesData: []
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`services`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    sectionName: response.heading,
                    sectionTitle: response.title,
                    servicesData: response.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    render() {
        //Service loop start
        const servicedata = this.state.servicesData && (this.state.servicesData.map((service, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
                <LinkContainer to={service.url} exact >
                    <div className="service-box text-center">
                        <div className="services ">
                            <img src={service.image.url} alt="serviceimage" width="100%"/>
                        </div>
                        <div>
                            <h6 className="uppercase">{service.title}</h6>
                            {service.description ? service.description : ""}</div>
                    </div>
                </LinkContainer>

            </div>
        )));
        //Service loop END
        return (
            <React.Fragment>
                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area"}>
                    <div className="container">
                        <div className="section-title">
                            <h3><strong>Our</strong> Services</h3>

                        </div>
                        {this.state.pageName === "home" ? '' : <div className="row px10">{servicedata}</div>}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;
