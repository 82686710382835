import React from 'react';
import Services from '../components/versionTwo/Services';
import Testimonial from '../components/versionTwo/Testimonial';
import BriefContact from '../components/common/briefContact';
import SupportServices from '../components/common/SupportServices';
import { axiosPage } from '../API';
import Showcase from "../components/versionTwo/Showcase";
import GetStartedForm from '../components/common/GetStartedForm';

class WebDevelopment extends React.Component {
    constructor(props) {
        super(props);
        // 

        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            sectionName: "Development",
            section1Image: "",
            section1Title: "",
            section1Description: "",
            section2Title: "",
            section2Description: "",
            section1Logos: "",
            section2Image: "",
			section6Title: "",
            section6Services: [],
			section7Title: "",
            section7Services: [],
        };
        this.getData();
        this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`development`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionTwo = response.section2;
				const section6 = response.section6;
				const section7 = response.section7;
                this.setState({
                    data: response,
                    showcaseName: response.title ? response.title : '',
                    showcaseDescription: response.description ? response.description : '',
                    showcaseImage: response.image ? response.image.url : '',
                    showDevelopmentBanner: true,
                    section1Image: sectionOne.image.url,
                    section1Title: sectionOne.title,
                    section1Description: sectionOne.description,
                    section1Logos: sectionOne.logos,
                    section2Title: sectionTwo.title,
                    section2Description: sectionTwo.description,
                    section2Image: sectionTwo.image.url,
					section6Title: section6.title,
                    section6Services: section6.services,
					section7Title: section7.title,
                    section7Services: section7.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    handleChange(event) {
        console.log(event.target.value, "s.state");
        let value = event.target.value;
        this.setState({
            selectedEnquiry: value,
        });
    }
    render() {
		const section6 = <div className="row">
            {this.state.section6Services && this.state.section6Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className=" service-box text-center">
						<div class="service-box-pic"><img src={value.image.url} alt="serviceimage" width="100" height="100" /></div>
                        <h6 className="service-content">{value.title}</h6>
                    </div>
                </div>
            )}
        </div>
		const section7 = <div className="row ">
            {this.state.section7Services && this.state.section7Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="service-style1-box">
                        <div className="service-style1-pic">
                            <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
							<p>{value.desc}</p>
						</div>
						<h6>{value.title}</h6>
                    </div>
                </div>)}
        </div>
        const sectionOne =
            <React.Fragment >
                <div className="textSection pt50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 pic">
                                <div className="img">
                                    <img src={this.state.section1Image} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 info">
								<h3>{this.state.section1Title}</h3>
								<p className="mb-30">{this.state.section1Description}</p>
								<div className="row">
									{this.state.section1Logos && (this.state.section1Logos.map((value, index) => {
										return (index <= 1 ?
											<div key={index} className="col-lg-6 col-md-6">
												<img src={value.choose_image.url} alt="img"  />
											</div> : '')
									}))
									}
								</div>
								<div className="row">
									{this.state.section1Logos && (this.state.section1Logos.map((value, index) => {
										return (index > 1 ?
											<div key={index} className="col-lg-6 col-md-6">
												<img src={value.choose_image.url} alt="img"  />
											</div> : '')
									}))
									}
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        const sectionTwo =
            <React.Fragment >
                <div className="textSection">
                    <div className="container">
                        <div className="row">                      
                            
							<div className="col-lg-6 col-md-6 info">
								<h3>{this.state.section2Title}</h3>
								<p>{this.state.section2Description}</p>
                            </div>
							<div className="col-lg-6 col-md-6 pic">
                                <div className="img">
                                    <img src={this.state.section2Image} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        
		return (
            <React.Fragment >
                {this.state.showDevelopmentBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                {sectionOne}
                {sectionTwo}
				<section className="services-area pt0">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section6Title}</h3>
                        </div>
						{section6}
                    </div>
                </section>
				<section className="service-style1">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section7Title}</h3>
                        </div>
                        {section7}
                    </div>
                </section>
				<GetStartedForm />
                <Testimonial pageName="development" />
            </React.Fragment>
        );
    }
}

export default WebDevelopment;