import React from 'react';
import PropTypes from "prop-types";
import AboutComponent from '../components/common/About'
import Testimonial from "../components/versionTwo/Testimonial";
import GetStartedForm from "../components/common/GetStartedForm";

class About extends React.Component {
   
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <AboutComponent pageName="aboutPage"  />
				<GetStartedForm pageName="Hire" />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" /> 
            </React.Fragment>
        );
    }
}

export default About;