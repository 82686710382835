import React from "react";
import ReactDOM from "react-dom";
import { Route, HashRouter} from "react-router-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(
    <HashRouter basename="/">
        <Route path="/" component={App} />
    </HashRouter>,
    document.getElementById("root")
)
