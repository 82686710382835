import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import { axiosPage } from '../../API';

class Partners extends Component {
    constructor(state) {
        super(state);
        this.state = {
            data: '',
            partnersData: '',
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`clients`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    partnersData: response
                }
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    render() {
        //Partner loop start
        const partnerData = this.state.partnersData && (this.state.partnersData||null).map((partner, index) => (
        
                <div className="col-lg-12 col-md-12" key={index}>
            {    /* <a href={partner.partnerLink}> */}
                    <img src={partner} alt="partner" />
                {/* </a> */}
                </div>
        )
         );
        //Partner loop END

        return (
            <React.Fragment>
                <div className="partner-area">
                    <div className="container">
                    <div className="section-title">
                            <h3><strong>Our</strong> Client Base</h3>
                        </div>
                        <div className="row">
                            <OwlCarousel
                                className="owl-theme partner-slides"
                                loop
                                autoplay={true}
                                nav={false}
                                mouseDrag={true}
                                autoplayHoverPause={true}
                                responsiveClass={true}
                                dots={false}
                                navText={[
                                    "<i class='icofont-curved-double-left'></i>",
                                    "<i class='icofont-curved-double-right'></i>"
                                ]}
                                responsive={{
                                    0: { items: 2 },
                                    768: {
                                        items: 4
                                    },
                                    1200: {
                                        items: 5
                                    }
                                }}
                            >
                                {partnerData}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default Partners;
