import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Subscribe from '../components/common/Subscribe';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";
import GetStartedForm from "../components/common/GetStartedForm";

class Writing extends React.Component {
    constructor(props) {
        super(props);
        // 

        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            sectionName: "Writing",
            section1Services: [],
            section1Title: "",
            section1Description: "",
			pwaFeatures: [],
            showWritingBanner: false
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`writing`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showWritingBanner: true,
                    section1Services: sectionOne.services,
                    section1Title: sectionOne.title,
                    section1Description: sectionOne.description,
					pwaFeatures: response.features,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const sectionOne = this.state.section1Services && (this.state.section1Services.map((value, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="service-box text-center">
                    <div className="service-box-pic">
                        <img src={value.image.url} alt="sectionimage" height="80" width="80" />
                    </div>
                    <h6>{value.title}</h6>
                </div>
            </div>
        ))); 

		const pwaFeatures = this.state.pwaFeatures ? this.state.pwaFeatures.map((feature, index) => (
			<React.Fragment key={index}>
				{index % 2 === 0 ? (
					<div className="textSection">
						<div className="container">
							<div className="row mtb-50">
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="textSection">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)) : '';

        return (
            <React.Fragment >
				
                {this.state.showWritingBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
				
				{pwaFeatures}
				
                <section id="services" className="services-area ptb-50">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section1Title}</h3>
                            <p>{this.state.section1Description}</p>
                        </div>
                        <div className="row">{sectionOne}</div>
                    </div>
                </section>
				
				<GetStartedForm pageName="Hire" />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />    
            </React.Fragment>
        );
    }
}

export default Writing;