import React, { Component } from "react";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import axios from "axios";
import qs from 'querystring';

class ContactForm extends Component {
    constructor(state) {
        super(state);
        this.state = {
            naame: "",
            email: "",
            subject: "",
            message: "",
            successMsg: ""
        };
    }
    handleForm = e => {
        let that = this;
        console.log(this.state,"ocntact form");
        axios
            .post("https://outsourcing.m2agency.co.uk/wp-json/contact-form-7/v1/contact-forms/5/feedback", qs.stringify(this.state), {
                headers: { Accept: "application/json" }
            })
            .then(function (response) {
                console.log('response:', response)
                const data= response.data;
                if(data.status==='validation_failed'){
                    that.setState({
                        errorMsg: data.invalidFields[0].message
                    });
                }else{
                    that.setState({
                        successMsg: data.message
                    });
                }
                document.getElementById("contactForm").reset();
            })
            .catch(function (error) { });
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    render() {
        return (
            <React.Fragment>
               <ValidationForm
                                            id="contactForm"
                                            onSubmit={(e, formData, inputs) => {
                                                e.preventDefault();
                                                this.handleForm(formData);
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="naame"
                                                            id="naame"
                                                            required
                                                            successMessage=""
                                                            errorMessage="Please enter your name"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            autoComplete="off"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="email"
                                                            id="email"
                                                            type="email"
                                                            required
                                                            successMessage=""
                                                            errorMessage="Please enter your email address"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            autoComplete="off"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                        <div className="help-block with-errors" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="subject"
                                                            id="subject"
                                                            type="text"
                                                            //required
                                                            successMessage=""
                                                            errorMessage="Please enter your email subject"
                                                            className="form-control"
                                                            placeholder="Subject"
                                                            autoComplete="off"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <TextInput
                                                            name="message"
                                                            id="description"
                                                            multiline
                                                            placeholder="Your message"
                                                            className="form-control"
                                                            required
                                                            successMessage=""
                                                            errorMessage="Please write your message"
                                                            rows="5"
                                                            autoComplete="off"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group alignCenter">
                                                <button className="btn btn-primary">
                                                    Send
                                                 </button>
                                            </div>
                                            <div className="clearfix" />
                                        </ValidationForm>
                                        {this.state.successMsg !== "" ? (
                                            <h4 className="contactMsg alignCenter">
                                                {this.state.successMsg}
                                            </h4>
                                        ) : <h4 className="contactErrorMsg alignCenter">
                                            {this.state.errorMsg}
                                            </h4>
                                        }
            </React.Fragment>
        );
    }
}

export default ContactForm;
