import axios from 'axios';
export const  version='v1/';
export const pageUrl='/pages/'+version;
export const menuUrl='/menus/'+version;
export const headers={
  'Access-Control-Allow-Origin': 'https://outsourcing.m2agency.co.uk/wp-json/'
}

export const axiosMenu = axios.create({
  baseURL: `https://outsourcing.m2agency.co.uk/wp-json`+menuUrl,
  responseType: "json"
});
export const axiosPage = axios.create({
  baseURL: `https://outsourcing.m2agency.co.uk/wp-json`+pageUrl,
  responseType: "json"
});
export const subscribe = axios.create({
  baseURL: 'https://outsourcing.m2agency.co.uk/wp-json/'+version,
  responseType: "json",
  headers: headers
});
