import React from 'react';
import PropTypes from "prop-types";
 
class Services extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
        //Service loop start
        const servicedata = this.props.servicesData.map((service, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-services text-center">
                    <i className={service.icon} />
                    <h3>{service.heading}</h3>
                    <p>{service.description}</p>
                </div>
            </div>
        ));
        //Service loop END
        return (
            <React.Fragment>
                {/* <NavBarMultiPage pageName="services" /> */}
                <div className="page-title">
                    <div className="pattern-2" />
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h3>{this.props.sectionName}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="services" className="services-area ptb-50 pb-0">
                    <div className="container">
                        <div className="row">{servicedata}</div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
Services.propTypes = {
    sectionName: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    sectionName: "Our Main Services",
    servicesData: [
        {
            icon: "icofont-ruler-pencil",
            heading: "Software Design",
            description:
                "Our designers implement the latest technology in creating software that will help you accomplish your goals."
        },
        {
            icon: "icofont-laptop-alt",
            heading: "Web Design",
            description:
                "Our team focuses on developing creative and convertible websites that can carve a special mark for them."
        },
        {
            icon: "icofont-brand-designfloat",
            heading: "Graphic Design",
            description:
                "We excel in creating visual content to communicate your messages effectively."
        },
        {
            icon: "icofont-ssl-security",
            heading: "Web Security",
            description:
                "We make sure to protect your websites or web applications from cyber attacks and hacking."
        },
        {
            icon: "icofont-globe-alt",
            heading: "Online Marketing",
            description:
                "Our digital marketing experts excel in catering the potential customers via digital strategies."
        },
        {
            icon: "icofont-letterbox",
            heading: "Branding Design",
            description:
                "We know how valuable this silent ambassador is and what kind of design can be instantly identified even in the crowd."
        }
    ]
};
 
export default Services;