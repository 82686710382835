import React from 'react';
import { axiosPage } from '../API';
import Showcase from "../components/versionTwo/Showcase";
import GetStartedForm from "../components/common/GetStartedForm";
import Testimonial from "../components/versionTwo/Testimonial";
import Industries from "../components/common/Industries";
import OurProcess from "../components/common/OurProcess";

class MongoDB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:"",
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner:false,
            section1leftTitle: "",
            section1leftDescription: "",
            section1rightTitle: "",
            section1rightDescription: "",
            section2Title: "",
            section2Services: [],
            section2Description: "",
            section3Title: "",
            section3Services: [],
            section3Description: "",
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`mongodb`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const section1 = response.section1;
                const section2 = response.section2;
                const section3 = response.section3;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section1leftTitle: section1.left_side.title,
                    section1leftDescription: section1.left_side.description,
                    section1rightTitle: section1.right_side.title,
                    section1rightDescription: section1.right_side.description,
                    section2Title: section2.title,
                    section2Services: section2.services,
                    section2Description: section2.description,
                    section3Title: section3.title,
                    section3Services: section3.services,
                    section3Description: section3.description,
                   
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const section1 = <div className="row mtb-50">
        <div className="col-lg-12 col-md-12">
            <div className="about-text mb-0">
                <h1>{this.state.section1leftTitle}</h1>
                <p className="mb-30">{this.state.section1leftDescription}</p>
            </div>
        </div>
        {/* <div className="col-lg-6 col-md-6">
            <div className="about-text mb-0">
                <h1>{this.state.section1rightTitle}</h1>
                <p className="mb-30">{this.state.section1rightDescription}</p>
            </div>
        </div> */}
    </div>
    const section2 = <div className="row ">
        {this.state.section2Services && this.state.section2Services.map((value, index) =>
            <div className="col-lg-15 col-md-3 col" key={index}>
                <div className=" text-center">
                    <div className="services  pb-20">
                        <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
                        <h6>{value.title}</h6>
                    </div>
                </div>
            </div>)}
    </div>

    const section3 = <div className="row">
        {this.state.section3Services && this.state.section3Services.map((value, index) =>
            <div className="col-lg-3 col-md-4 col pad20" key={index}>
                <div className=" service ">
                <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
                    <h6 className="service-content">{value.title}</h6>
                </div>
            </div>
            )}
    </div>
        return (
            <React.Fragment >
                {this.state.showBanner && 
                (<Showcase    
                    showcaseImage={this.state.showcaseImage} 
                    showcaseName={this.state.showcaseName}    
                    showcaseDescription={this.state.showcaseDescription}   />)
                }
                 <section className="ov-hide bg-gray">
                    <div className="container">
                        {section1}
                    </div>
                </section>
                <section className="ov-hide">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section2Title}</h3>
                        </div>
                        {section2}
                    </div>
                </section>
                <section className="ov-hide bg-gray">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section3Title}</h3>
                            <p>{this.state.section3Description}</p>
                        </div>
                        {section3}
                    </div>
                </section>
                <OurProcess className="ov-hide" />
                <Industries />
                <Testimonial pageName="bigcommerce" />
                <GetStartedForm pageName="Hire" />
            </React.Fragment>
        );
    }
}

export default MongoDB;