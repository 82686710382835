import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import Subscribe from "../components/common/Subscribe";
import GetStartedForm from '../components/common/GetStartedForm';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";

class Career extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            section1Title: "",
            section1Description: "",
            section1Services: "",
            showBanner:false,
            formData:{
                naame: "",
                email: "",
                subject: "",
                message: "",
            },
            successMsg: ""
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`career`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner:true,
                    section1Image: sectionOne.image? sectionOne.image.url:"",
                    section1Title: sectionOne.title,
                    section1Description: sectionOne.description,
                    section1Services:  sectionOne.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const servicedata = this.state.section1Services && (this.state.section1Services.map((service, index) => (
            <div className="col-md-4" key={index}>
                <div className="interProBox text-center">
                    <div className="interProBoxPic">
                        <img src={service.image && service.image.url} alt="serviceimage" width="300" />
                    </div>
                    <h6>{service.title}</h6>
                    <p>{service.description}</p>
                </div>
            </div>
        )));

        // const jobForm=

        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "interviewProcess ptb-50 "}>
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section1Title}</h3>
                            <p>{this.state.section1Description}</p>
                        </div>
                        <div className="row">{servicedata}</div>
                    </div>
                </section>
                <GetStartedForm />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />   
            </React.Fragment>
        );
    }
}

export default Career;