import React from 'react';
import { axiosPage } from '../API';
import Subscribe from "../components/common/Subscribe";
import Showcase from "../components/versionTwo/Showcase";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            description: '',
            content: "",
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`privacy_policy`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner: true,
                    description: response.heading ? response.heading : "",
                    content: response.content,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const data = this.state.content && (this.state.content.map((service, index) => (
                <div className="section-title about-text" key={index}>
                    <h3>{service.title}</h3>
                    <p>{service.list_title && service.list_title}</p>
                    <ul>
                    {service.listing && service.listing.map((list, i) =>
                        <li className="listing" key={i}>
                            <i className="icofont-ui-check" />
                            {list.add_list}
                    </li>)}
                    </ul>
                    <p>{service.description}</p>
                </div>
        )));

        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section id="services" className='mt-30'>
                    <div className="container">
                        <div className="section-title">
                            <p>{this.state.description}</p>
                        </div>
                        {data}
                        {/* <div className="row">{data}</div> */}
                    </div>
                </section>
                <Subscribe />
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;