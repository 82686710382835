import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, Nav, InputGroup, FormControl, NavDropdown, Dropdown } from "react-bootstrap";
import { axiosMenu } from '../../API';
import axios from 'axios';
import qs from 'querystring';
class NavBarMultiPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '',
            menu: "",
            search: "",
            searchList: [],
            mainHeader: [],
            width: window.innerWidth,

        };
        this.handleFields = this.handleFields.bind(this);
        // localStorage.setItem('menu', "");
        // if (!localStorage.getItem('menu') || localStorage.getItem('menu') === undefined) {
        this.getMenu();
        // } else {
        // const menu = JSON.parse(localStorage.getItem('menu'));
        // this.state.menu = menu;
        // this.setState({menu:menu});
        // }
    }

    // make sure to remove the listener
    // when the component is not mounted anymore

    componentDidMount() {
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.setState({ scrollWithOffset });

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };
    getMenu() {
        axiosMenu
            .get(`menus/Totally-Outsourcing-Menu`)
            .then(result => {
                localStorage.setItem('menu', JSON.stringify(result.data.items));
                this.setState({
                    data: result.data,
                    isLoading: false,
                    menu: result.data.items,
                });
                console.log('this.state.menu:', this.state.menu)
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }
    handleFields(e) {
        e.persist();
        console.log('e:', e)
        console.log(e.target.value, "console.log(e.target.value)")
        this.setState({ search: e.target.value })
        console.log(this.state);
        let self = this;
        if (e.target.value && e.target.value.length > 0) {
            let url = "https://outsourcing.m2agency.co.uk/wp-json/v1/search";
            // console.log(this.state.search)
            let data = {
                s: e.target.value,
            }

            axios
                .post(url, qs.stringify(data), { headers: { Accept: "application/json" } })
                .then(result => {
                    const response = result.data;
                    if (response) {
                        this.setState({
                            searchList: response,
                            isLoading: false
                        })
                    }
                })
                .catch(error => {
                    console.log('error:', error)
                    self.setState({
                        status: "error",
                        errorMessage: "OOPS, something went wrong. We are working on this.",
                        isLoading: false
                    })
                }
                );
        }
    };

    render() {
        const urlprefix = "#";
        const { width } = this.state;
        const searchSection = <Dropdown>
            <Dropdown.Toggle id="dropdown-custom-components">
                <InputGroup >
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1"><i className="fas fa-search"></i></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="search"
                        type="text"
                        defaultValue={this.state.search}
                        onChange={this.handleFields}
                    />
                </InputGroup>
            </Dropdown.Toggle>

            {this.state.search.length && this.state.searchList.length ? (<Dropdown.Menu >
                {this.state.searchList.map((value, index) => (
                    <Dropdown.Item eventKey="1" href={urlprefix + value.url} key={index}>{value.title}</Dropdown.Item>
                ))}
            </Dropdown.Menu>) : <Dropdown.Menu >
                    <Dropdown.Item eventKey="1">If you find nothing, please go to another search.</Dropdown.Item>
                </Dropdown.Menu>}
        </Dropdown>

        const isNotDesktop = width <= 991;

        const menus = this.state.menu && this.state.menu.length && this.state.menu.map((value, index) => {
            if (value.type === "dropdown") {
                return <NavDropdown title={value.title} id="basic-nav-dropdown" key={index}>
                    {value.options.map((option, index1) => (
                        <NavDropdown.Item href={'#' + option.url} key={index1}>{option.title}</NavDropdown.Item>
                    ))}
                </NavDropdown>
            }
            else if (value.type === "coldropdown") {
                return <NavDropdown className="megamenu" title={value.title} id="basic-nav-dropdown" key={index}>
                    <div className="row">
                        {value.options.map((option, index1) => (
                            <div key={index1} className="col-md-6 col-xl-3 sub-menu mb-xl-0 mb-4 col-lg-3">
                                <h6 className="sub-title text-uppercase font-weight-bold white-text">{option.title}</h6>
                                <ul className="list-unstyled">
                                    {option.options.map((list, index2) => (
                                        <li key={index2}>
                                            <NavDropdown.Item href={"#" + list.url} key={index1}>{list.title}</NavDropdown.Item>
                                            {/* <a className="menu-item pl-0" href="#!">
                                                    <i className="fas fa-caret-right pl-1 pr-3"></i>{list.title}
                                                </a> */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </NavDropdown>
            }
            else {
                return <LinkContainer to={value.url} activeClassName="showactive" exact key={index} >
                    <Nav.Link >{value.title}</Nav.Link>
                </LinkContainer>
            }
        }
        )

        if (isNotDesktop) {
            return (
                <React.Fragment>
                    <Navbar
                        sticky="top"
                        id="navbar"
                        bg="light"
                        expand="lg"
                        className="navbar-expand-lg navbar-light bg-light"
                        collapseOnSelect={true}
                    >
                        <Container className="custom-cont">
							<LinkContainer exact to="/">
								<img
									src={this.props.logoImage}
									alt="about" width="210"
								/>
							</LinkContainer>
                            <Navbar.Toggle
                                aria-controls="basic-navbar-nav"
                                id="collaspe-btn"
                            />
                            <Navbar.Collapse className="mobileNav" id="basic-navbar-nav">
                                {searchSection}
                                <Nav className="ml-auto above-nav">
                                    <LinkContainer to='/avbout' activeClassName="showactive" exact className="uppercase" >
                                        <Nav.Link >About</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/how-it-works' activeClassName="showactive" exact className="uppercase" >
                                        <Nav.Link>How it works</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/hire' activeClassName="showactive" exact className="uppercase" onClick={this.closeNavbar.bind(this)}>
                                        <button className="btn btn-primary " size="sm" >
                                            Hire Now
                                    </button>
                                    </LinkContainer>
                                    <hr />
                                    {menus}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </React.Fragment >
            );
        } else {

            return (
                <React.Fragment>
                    <Navbar
                        sticky="top"
                        id="navbar"
                        bg="light"
                        expand="lg"
                        className="navbar-expand-lg navbar-light bg-light"
                        collapseOnSelect={true}
                    >

                        <Container className="custom-cont">
							<div className="logo">
								<LinkContainer exact to="/">
									<img
										src={this.props.logoImage}
										alt="about" width="210"
									/>
								</LinkContainer>
							</div>
                            <Navbar.Toggle
                                aria-controls="basic-navbar-nav"
                                id="collaspe-btn"
                            />

                            <Navbar.Collapse id="basic-navbar-nav">
                                {searchSection}

                                <Nav className="ml-auto above-nav">
                                    <LinkContainer to='/about' activeClassName="showactive" exact className="uppercase" >
                                        <Nav.Link >About</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/how-it-works' activeClassName="showactive" exact className="uppercase" >
                                        <Nav.Link>How it works</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to='/hire' exact className="uppercase">
                                        <button className="btn btn-primary " size="sm" >
                                            Hire Now
                                        </button>
                                    </LinkContainer>
                                </Nav>

                            </Navbar.Collapse>
                        </Container>
                        <Container className="cutm-navbar">
                            <div className="row customNav">
                                <div className="nav-bar">
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="ml-auto">
                                            {menus}
                                        </Nav>
                                    </Navbar.Collapse>
                                </div>
                            </div>
                        </Container>
                    </Navbar>
                </React.Fragment >
            );
        }
    }
}

                 

export default NavBarMultiPage;

NavBarMultiPage.defaultProps = {
    logoImage: require("../../assets/img/logo.svg")
}
