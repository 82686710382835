import React, { Component } from "react";
import { Card } from 'react-bootstrap';
import { axiosPage } from '../../API';
import Showcase from "../versionTwo/Showcase";

class HireComponent extends Component {

    constructor(state) {
        super(state);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            sectionName: '',
            sectionTitle: '',
            sectionData: [],
            showHireBanner:false
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`hire`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showHireBanner:true,
                    sectionTitle: response.sectionTitle,
                    sectionData: response.data,
                },
                    console.log(this.state.data)
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    render() {
        //Works Data Loop Start
        const hireData = this.state.sectionData ? this.state.sectionData.map((value, index) => (
            <div className="col-md-4" key={index}>
                <div className="single-box">
					<div class="alignCenter"><img src={value.image} /></div>
                    <h6>{value.title}</h6>
                    <p>{value.description}</p>
                </div>
            </div>
        )) : '';

        return (
            <React.Fragment>
                {this.state.showHireBanner && this.props.pageName ==="hire" && 
    (<Showcase
        showcaseImage={this.state.showcaseImage}
        showcaseName={this.state.showcaseName}
        showcaseDescription={this.state.showcaseDescription}
		/>)
}
               
               
                <section className={this.props.pageName === "home" ? "how-works-area" : 'how-works-area  ptb-50'}>
                    <div className="container hireContainer">
                        {this.props.pageName === "home" ?
                            (<div>
								<div className="section-title">
									<h3><strong>Hire</strong> for any Scope of Work</h3>
								</div>
                                <div className="row">
                                    {hireData}
                                </div>
                            </div>)
                            : (<div >
                                <div className="section-title">
                                    <h6>{this.state.sectionTitle}</h6>
                                </div>
                                <div className="row">
                                    {this.state.sectionData && this.state.sectionData.map((value, index) => (
                                        <div className="col-md-4" key={index}>
                                            <div className="single-box">
                                                <h6>{value.title}</h6>
                                                <p>{value.description}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>)}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}


export default HireComponent;
