import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Subscribe from "../components/common/Subscribe";
import GetStartedForm from '../components/common/GetStartedForm';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";

class MaintainSupport extends React.Component {
    constructor(props) {
        super(props);
        // 
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            section3Title: "",
            section3Services: [],
			pwaFeatures: [],
            showMaintainBanner:false
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`maintenance_support`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionThree = response.section3;
              
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showMaintainBanner:true,
                    section3Title: sectionThree.title,
                    section3Services: sectionThree.services,
					pwaFeatures: response.features,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
		const pwaFeatures = this.state.pwaFeatures ? this.state.pwaFeatures.map((feature, index) => (
			<React.Fragment key={index}>
				{index % 2 === 0 ? (
					<div className="textSection">
						<div className="container">
							<div className="row mtb-50">
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="textSection">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)) : '';  
        const servicedata = this.state.section3Services && (this.state.section3Services.map((service, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="service-box text-center">
                    <div className="service-box-pic">
                        <img src={service.image && service.image.url} alt="serviceimage" width="80" height="80"/>
                    </div>
                    <h6>{service.title}</h6>
                </div>
            </div>
        )));

        return (
            <React.Fragment >
                {this.state.showMaintainBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
				{pwaFeatures}
                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area"}>
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section3Title}</h3>
                        </div>
                        <div className="row">{servicedata}</div>
                    </div>
                </section>
                <GetStartedForm />
				<Testimonial pageName="home" />   
            </React.Fragment>
        );
    }
}

export default MaintainSupport;