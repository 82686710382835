import React from 'react';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";
import GetStartedForm from "../components/common/GetStartedForm";

class Pwa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            pwaFeatures: [],
            section5Title: '',
            section5Description: '',
            section5Services: [],
            section6Title: '',
            section6Description: '',
            section6Services: [],
            showPwaBanner:false

        };
        this.getData();

    }

    getData() {
        axiosPage
            .get(`pwa`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionFive = response.section5;
                const sectionSix = response.section6;
                this.setState({
                    showcaseName: sectionOne.title,
                    showcaseDescription: sectionOne.description,
                    showcaseImage: sectionOne.image.url,
                    showPwaBanner:true,
                    pwaFeatures: response.features,
                    section5Title: sectionFive.title,
                    section5Description: sectionFive.description,
                    section5Services: sectionFive.services,
                    section6Title: sectionSix.title,
                    section6Description: sectionSix.description,
                    section6Services: sectionSix.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const pwaFeatures = this.state.pwaFeatures ? this.state.pwaFeatures.map((feature, index) => (
            <React.Fragment key={index}>
                {index % 2 === 0 ? (
                    <div className="textSection">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 pic">
                                    <div className="img">
                                        <img src={feature.image.url} alt="img" />
                                    </div>
                                </div>
                                <div className="col-md-5 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i class="fas fa-check-circle"></i>
												{value.add_list}
											</li>
										))}
									</ul> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="container textSection">
                            <div className="row">
                                <div className="col-md-5 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i class="fas fa-check-circle"></i>
												{value.add_list}
											</li>
										))}
									</ul> : ''}
                                </div>
                                <div className="col-md-7 pic">
                                    <div className="img">
                                        <img src={feature.image.url} alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </React.Fragment>
        )) : '';
        const useOfTO = this.state.section6Services && (this.state.section6Services.map((value, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
                <div className="single-work alignCenter">
                    <div class="alignCenter mb-30"><img src={value.image && value.image.url} alt="TO-Use" /></div>
                    <h3>{value.title}</h3>
                    <p>{value.description}</p>
                </div>
            </div>
        )));
        const servicedata = this.state.section5Services && (this.state.section5Services.map((service, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="service-box text-center">
                    <div class="service-box-pic"><img src={service.image && service.image.url} alt="service-image" width="80" height="80"/></div>
                    <h6>{service.title}</h6>
                    <p>{service.description}</p>
                </div>
            </div>
        )));
        return (
            <React.Fragment >
                {this.state.showPwaBanner && 
                (<Showcase    
                    showcaseImage={this.state.showcaseImage} 
                    showcaseName={this.state.showcaseName}    
                    showcaseDescription={this.state.showcaseDescription}   />)
                }
                <div>
                    {pwaFeatures}
                    <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area ptb-50"}>
                        <div className="container">
                            <div className="section-title">
                                <h3>{this.state.section5Title}</h3>
                                <p>{this.state.section5Description}</p>
                            </div>
                            {this.props.pageName === "home" ? '' : <div className="row">{servicedata}</div>}
                        </div>
                    </section>
                    <div className="stepWork ov-hide">
                        <div className="container workspage">
                            <div className="section-title">
                                <h3>{this.state.section6Title}</h3>
                                <p>{this.state.section6Description}</p>
                            </div>
                            <div className="row">{useOfTO}</div>
                        </div>
                    </div>
                </div>
				<GetStartedForm pageName="Hire" />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />     
                {/* : ''} */}
            </React.Fragment>
        );
    }
}

export default Pwa;