import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import Subscribe from "../components/common/Subscribe";
import GetStartedForm from '../components/common/GetStartedForm';
import Showcase from "../components/versionTwo/Showcase";
// import { LinkContainer ,Nav} from "react-router-bootstrap";

class SiteMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            showBanner: false,
            links: [],
            formData: {
                naame: "",
                email: "",
                subject: "",
                message: "",
            },
            successMsg: ""
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`sitemap`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner: true,
                    links:response.links,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }

                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area  ptb-50 siteMap"}>
                    <div className="container">
                        {/* <div className="section-title">
                            <h3>{this.state.section1Title}</h3> 
                            <p>{this.state.section1Description}</p>
                        </div> */}
                        <div className="row">
                            {this.state.links && this.state.links.map((value, index) => (
                                <div className="col-lg-4 col-md-6 " key={index}>
                                    {/* <LinkContainer to={value.url ? value.url : ""} exact key={index}> */}
                                        <div className="service-box pad20" style={{padding:"20px"}}>
                                            <div>
                                            <h6 className="uppercase">{value.title}</h6>
                                                <ul>
                                                    {value.children && value.children.map((value1, index1) => (
                                                        <li key={index1}>
                                                            {value1.children ?
                                                                <span>{value1.title}
                                                                    <ul>
                                                                        {value1.children.map((value2, index2) => (
                                                                            <li key={index2}>
                                                                                <a href={value2.url}>{value2.title}</a>  
                                                                                </li>
                                                                        ))}
                                                                    </ul></span> :<a href={value1.url}>{value1.title}</a>
                                                                    }
                                                        </li>))}
                                                </ul>
                                            </div>
                                        </div>
                                    {/* </LinkContainer> */}

                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <GetStartedForm />
                <BriefContact pageName="maintainenace" />
                <Subscribe />
            </React.Fragment>
        );
    }
}

export default SiteMap;