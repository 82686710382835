import React, { Component } from "react";

// //Components List
import Showcase from "../components/versionTwo/Showcase";
import Services from "../components/versionTwo/Services";
import Works from "../components/common/Works";
import Testimonial from "../components/versionTwo/Testimonial";
import Partners from "../components/common/Partners";
import Subscribe from "../components/common/Subscribe";
import HireComponent from "../components/common/Hire";
import GetStartedForm from "../components/common/GetStartedForm";

class HomeTwo extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                {/* Showcase: src/components/versionTwo/Showcase */}
                <Showcase showcaseHomeClass='Homepage-banner' />
                <Services />
                {/* Works: src/components/common/Works */}
                <Works />
                <HireComponent pageName="home" />
                {/* Team: src/components/versionTwo/Team */}
                {/* <Team /> */}
				{/* Partners: src/components/common/Partners */}
                <Partners />
				<GetStartedForm pageName="Hire" />
                {/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />                
                {/* Subscribe: src/components/common/Subscribe */}
                <Subscribe />

            </React.Fragment>
        );
    }
}

export default HomeTwo;
