import React from 'react';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";
import GetStartedForm from "../components/common/GetStartedForm";
import Testimonial from "../components/versionTwo/Testimonial";
import Industries from "../components/common/Industries";
import OurProcess from "../components/common/OurProcess";

class ClickService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner: false,
			pwaFeatures: [],
            section2Title: "",
            section2Image: "",
            section2Listing: [],
            section3Title: "",
            section3Image: "",
            section3Listing: [],
            section4Title: "",
            section4Services: [],
			section6Title: "",
            section6Services: [],
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`pay-per-click-services`)
            .then(result => {
                const response = result.data;
                const section2 = response.section2;
                const section3 = response.section3;
                const section4 = response.section4;
                const section5 = response.section5;
                const section6 = response.section6;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section2Title: section2.title,
                    section2Services: section2.services,
                    section3Title: section3.title,
                    section3Services: section3.services,
                    section4Title: section4.title,
                    section4Services: section4.services,
					section6Title: section6.title,
                    section6Services: section6.services,
					pwaFeatures: response.features,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
		const pwaFeatures = this.state.pwaFeatures ? this.state.pwaFeatures.map((feature, index) => (
			<React.Fragment key={index}>
				{index % 2 === 0 ? (
					<div className="textSection">
						<div className="container">
							<div className="row mtb-50">
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="textSection">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 info">
									<h3>{feature.title}</h3>
									<p>{feature.description}</p>
									{feature.notes && (feature.notes.map((note, index) => (
										<div key={index}>
											<h4>{note.heading}</h4>
											<p>{note.content}</p>
										</div>
									)))
									}
									{feature.listing ? <ul className="pull-left checkList">
										{feature.listing.map((value, index) => (
											<li key={index}>
												<i className="icofont-ui-check" />
												{value.add_list}
											</li>
										))}
									</ul> : ''}
								</div>
								<div className="col-lg-6 col-md-6 pic">
									<div className="img">
										<img src={feature.image} alt="img" />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		)) : '';
        const section2 = <div className="row">
            {this.state.section2Services && this.state.section2Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className=" service-box text-center">
						<div class="service-box-pic"><img src={value.image.url} alt="serviceimage" width="80" height="80" /></div>
                        <h6 className="service-content">{value.title}</h6>
                    </div>
                </div>
            )}
        </div>
		const section3 = <div className="row">
            {this.state.section3Services && this.state.section3Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className=" service-box text-center">
						<div class="service-box-pic"><img src={value.image.url} alt="serviceimage" width="80" height="80" /></div>
                        <h6 className="service-content">{value.title}</h6>
                    </div>
                </div>
            )}
        </div>
        const section4 = <div className="row ">
            {this.state.section4Services && this.state.section4Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="service-style1-box">
                        <div className="service-style1-pic">
                            <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
							<p>{value.desc}</p>
						</div>
						<h6>{value.title}</h6>
                    </div>
                </div>)}
        </div>
		const section6 = <div className="row ">
            {this.state.section6Services && this.state.section6Services.map((value, index) =>
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="service-style1-box">
                        <div className="service-style1-pic">
                            <img src={value.image.url} alt="serviceimage" width="60" height="60"/>
							<p>{value.desc}</p>
						</div>
						<h6>{value.title}</h6>
                    </div>
                </div>)}
        </div>
        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
				{pwaFeatures}
                <section className="services-area">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section2Title}</h3>
                        </div>
                        {section2}
                    </div>
                </section>
                <section className="services-area pt0">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section3Title}</h3>
                        </div>
						{section3}
                    </div>
                </section>
                <section className="service-style1">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section4Title}</h3>
                        </div>
                        {section4}
                    </div>
                </section>
				<section className="service-style1">
                    <div className="container">
                        <div className="section-title">
                            <h3>{this.state.section6Title}</h3>
                        </div>
                        {section6}
                    </div>
                </section>
				<GetStartedForm pageName="Hire" />
                <Testimonial pageName="bigcommerce" />                
            </React.Fragment>
        );
    }
}

export default ClickService;