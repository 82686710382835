import React from 'react';
import { axiosPage } from '../API';
import Showcase from "../components/versionTwo/Showcase";
import GetStartedForm from "../components/common/GetStartedForm";
import Testimonial from "../components/versionTwo/Testimonial";
import Industries from "../components/common/Industries";
import OurProcess from "../components/common/OurProcess";

class Angularjs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            showBanner: false,
            section1Title: "",
            section1Description: "",
            section1Image: "",
            section2Title: "",
            section2Services: [],
            section3Title: "",
            section3Services: [],

        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`angular-js`)
            .then(result => {
                const response = result.data;
                const section1 = response.section1;
                const section2 = response.section2;
                const section3 = response.section3;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showBanner: true,
                    section1Title: section1.title,
                    section1Description: section1.description,
                    section1Image: section1.image ? section1.image.url : '',
                    section2Title: section2.title,
                    section2Services: section2.services,
                    section3Title: section3.title,
                    section3Services: section3.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        const section1 = <div className="row mtb-50">
            <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                    <img src={this.state.section1Image} alt="sectionImage" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="about-text mb-0">
                    <h1>{this.state.section1Title}</h1>
                    <p className="mb-30">{this.state.section1Description}</p>
                </div>
            </div>
        </div>
        const section2 = this.state.section2Services && this.state.section2Services.map((value, index) => (
            <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3 " key={index}>
                <div className="single-box dsplyBlck">
                    <h6>{value.title}</h6>
                    <p>{value.description}</p>
                </div>
            </div>));
  
        const section3 = this.state.section3Services && (this.state.section3Services.map((service, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                    <div className="about-text">
                        <div className="services">
                            <img className="service-image" src={service.image.url} alt="serviceimage" width="60" height="60"/>
                            <h6 className="uppercase title">{service.title}</h6>
                        </div>
                        <div className="pb-20">
                            
                            {service.description ? <p>{service.description}</p> : ""}
                        </div>
                    </div>
                </div>
            )));
        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section className="ov-hide bg-gray">
                    <div className="container">
                        {section1}
                    </div>
                </section>
                <section className="ov-hide">
                    <div className="container mtb-50">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
                                <h3>{this.state.section2Title}</h3>
                            </div>
                            {section2}
                        </div>
                    </div>
                </section>
                <section className="ov-hide bg-gray">
                    <div className="container mtb-50">
                        <div className="section-title">
                            <h3>{this.state.section3Title}</h3>
                        </div>
                        {section3}
                    </div>
                </section>
                <OurProcess className="ov-hide " />
                <Industries />
                <Testimonial pageName="bigcommerce" />
                <GetStartedForm pageName="Hire" />
            </React.Fragment>
        );
    }
}

export default Angularjs;