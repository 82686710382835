import React from 'react';
import { axiosPage } from '../API';
import BriefContact from '../components/common/briefContact';
import i from 'react-icofont';
import Showcase from "../components/versionTwo/Showcase";

class Marketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            sectionOneTitle: "",
            sectionOneDescription: "",
            sectionOneData: [],
            sectionOneContent: "",
            sectionTwoTitle: "",
            sectionTwoDescription: "",
            sectionTwoSubheader: "",
            sectionTwoSubDescription: "",
            sectionTwoListing: [],
            sectionTwoImage: '',
            sectionThirdTitle: "",
            sectionThirdListing1: [],
            sectionThirdListing2: [],
            sectionThirdImage: '',
            sectionFourTitle: "",
            sectionFourDescription: "",
            sectionFourData: [],
            showMarketingBanner: false
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`marketing`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionTwo = response.section2;
                const sectionThree = response.section3;
                const sectionFour = response.section4;

                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showMarketingBanner: true,
                    sectionOneTitle: sectionOne.title,
                    sectionOneDescription: sectionOne.description,
                    sectionOneData: sectionOne.services,
                    sectionOneContent: sectionOne.content,
                    sectionTwoTitle: sectionTwo.title,
                    sectionTwoDescription: sectionTwo.description,
                    sectionTwoSubheader: sectionTwo.sub_title,
                    sectionTwoSubDescription: sectionTwo.content,
                    sectionTwoListing: sectionTwo.listing,
                    sectionTwoImage: sectionTwo.image ? sectionTwo.image.url : '',
                    sectionThirdTitle: sectionThree.title,
                    sectionThirdListing1: sectionThree.listing1,
                    sectionThirdListing2: sectionThree.listing2,
                    sectionThirdImage: sectionThree.image ? sectionThree.image.url : '',
                    sectionFourTitle: sectionFour.title,
                    sectionFourDescription: sectionFour.description,
                    sectionFourData: sectionFour.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {

        const sectionOne = this.state.sectionOneData && (this.state.sectionOneData.map((value, index) => (
            <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3 " key={index}>
                <div className="text-center">
                    <div className="services ">
                        {/* <i className={value.icon} /> */}
                        <img src={value.image && value.image.url} alt="img" className="img-60"/>
                    </div>
                    <h6>{value.title}</h6>
                    <p>{value.description}</p>
                </div>
                {/* <div className="single-features">
                    <i className={value.icon} />
                    <h3>{value.featuresName}</h3>
                </div> */}
            </div>
        )));
        const sectionTwo = <div className="row mb-50">
            <div className="col-lg-6 col-md-6">
                <div className="img">
                    <img src={this.state.sectionTwoImage} alt="img" />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
                <div className="about-text mb-0">
                    <div >
                        <h4>{this.state.sectionTwoSubheader}</h4>
                        <p className="mb-30">{this.state.sectionTwoSubDescription}</p>
                    </div>
                    {this.state.sectionTwoListing ? <ul className="pull-left">
                        {this.state.sectionTwoListing.map((value, index) => (
                            <li key={index}>
                                <i className="icofont-ui-check" />
                                {value.list_name}
                            </li>
                        ))}
                    </ul> : ''}
                </div>
            </div>
        </div>
        const sectionThird = <div className="row mb-50">
            <div className="col-lg-4 col-md-4 col-12">
                <div className="img">
                    <img src={this.state.sectionThirdImage} alt="img" />
                </div>
            </div>
            <div className="col-lg-4  col-md-4 col-sm-6 col-xs-6">
                <div className="about-text iconalign mb-0">
                    {this.state.sectionThirdListing1 ? <ul className="pull-left">
                        {this.state.sectionThirdListing1.map((value, index) => (
                            <li key={index}>
                                <i className="icofont-curved-right"></i>
                                <div className="lftmrgn-20">{value.list_1}</div>
                            </li>
                        ))}
                    </ul> : ''}
                </div>
            </div>
            <div className="col-lg-4  col-md-4 col-sm-6 col-xs-6">
                <div className="about-text iconalign mb-0">
                    {this.state.sectionThirdListing2 ? <ul className="pull-left">
                        {this.state.sectionThirdListing2.map((value, index) => (
                            <li key={index}>
                                <i className="icofont-curved-right"></i>
                                <div className="lftmrgn-20">{value.list_2}</div>
                            </li>
                        ))}
                    </ul> : ''}
                </div>
            </div>
        </div>
        const sectionFour = this.state.sectionFourData && (this.state.sectionFourData.map((value, index) => (
            <div className="col" key={index}>
                <div className="text-center">
                    <div className="services">
                        <img src={value.image && value.image.url} alt="img" width="50" height="50"/>
                    </div>
                    <h6>{value.title}</h6>
                </div>
            </div>
        )));
        return (
            <React.Fragment >
                {this.state.showMarketingBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <div>
                    <section id="services" className="services-area bg-gray   ptb-50">
                        <div className="container">
                            <div className="section-title">
                                <h3>{this.state.sectionOneTitle}</h3>
                                <p>{this.state.sectionOneDescription}</p>
                            </div>
                            <div className="row">{sectionOne}</div>
                        </div>
                    </section>
                    <section className="ov-hide">
                        <div className="container">
                            <div className="section-title mtb-50">
                                <h3>{this.state.sectionTwoTitle}</h3>
                                <p>{this.state.sectionTwoDescription}</p>
                            </div>
                            {sectionTwo}
                        </div>
                    </section>
                    <section className="ov-hide bg-gray">
                        <div className="container">
                            <div className="section-title mtb-50">
                                <h3>{this.state.sectionThirdTitle}</h3>
                            </div>
                            {sectionThird}
                        </div>
                    </section>
                    <section id="services" className="services-area ptb-50">
                        <div className="container">
                            <div className="section-title">
                                <h3>{this.state.sectionFourTitle}</h3>
                                <p>{this.state.sectionFourDescription}</p>
                            </div>
                            <div className="row">{sectionFour}</div>
                        </div>
                    </section>
                </div>
                <BriefContact pageName="Marketing" />
                {/* : ''} */}
            </React.Fragment>
        );
    }
}

export default Marketing;