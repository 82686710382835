import React from 'react';
import Works from '../components/common/Works'

class HowWork extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render(){
       
        return (
            <React.Fragment>
                <Works pageName="workPage"/>
            </React.Fragment>
        );
    }
}


export default HowWork;