import React from 'react';
import HireComponent from "../components/common/Hire";
import GetStartedForm from "../components/common/GetStartedForm";

class Hire extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    submit(fieldData){
    console.log(fieldData,"fdaaraaaa");
    
    }
    render() {
        return (
            <React.Fragment>
                <HireComponent pageName="hire" />
                <GetStartedForm pageName="Hire" submit={this.submit}/>
            </React.Fragment>
        );
    }
}

export default Hire;