import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Page from "react-page-loading";

//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS Style
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";

//Component Import
import HomeTwo from "./pages/HomeTwo";
import Pwa from "./pages/Pwa";
import WebDevelopment from "./pages/WebDevelopment";
import Design from "./pages/Design";
import Marketing from "./pages/Marketing";
import MaintainSupport from "./pages/MaintainSupport";
import About from "./pages/About";
import HowWork from "./pages/HowWorks";
import Services from "./pages/Services";
import Team from "./pages/Team";
import Hire from "./pages/Hire";
import Faq from "./pages/Faq";
import Pricing from "./pages/Pricing";
import ContactUS from "./pages/ContactUs";
import ScrollUpBtn from "./components/common/ScrollUpBtn";
import NavBarMultiPage from "./components/common/NavBarMultiPage";
import Footer from "./components/common/Footer";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Consultancy from "./pages/consultancy";
import Hosting from "./pages/Hosting";
import Writing from "./pages/Writing";
import Career from "./pages/Career";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import SiteMap from "./pages/SiteMap";
// import Magento from "./pages/Magento";
import BigCommerce from "./pages/BigCommerce";
import Shopify from "./pages/Shopify";
import WooCommerce from "./pages/WooCommerce";
import Opencart from "./pages/Opencart";
import Drupal from "./pages/Drupal";
import WordpressDevelopment from "./pages/WordpressDevelopment";
import Joomla from "./pages/Joomla";
import Sharepoint from "./pages/Sharepoint";
import HubSpot from "./pages/HubSpot";
import CorePhp from "./pages/CorePhp";
import CakePhp from "./pages/CakePhp";
import Laravel from "./pages/Laravel";
import Yii from "./pages/Yii";
// import Socialmedia from "./pages/Socialmedia";
import ClickServices from "./pages/ClickServices";
import DigitalMarketing from "./pages/DigitalMarketing";
import ConversionRate from "./pages/ConversionRate";
import Angularjs from "./pages/Angularjs";
import Nodejs from "./pages/Nodejs";
import Meanstack from "./pages/Meanstack";
import MongoDB from "./pages/MongoDB";
import ReactNative from "./pages/ReactNative";

class App extends Component {
 
    render() {
        return (
            <div className="App">
                <div>
                    <Page loader={"bar"} color={"#506CEA"} size={9}>
                        <Route
                            render={({ location }) => (
                                <TransitionGroup className="transition-group">
                                    <CSSTransition
                                        key={location.key}
                                        timeout={{ enter: 900, exit: 900 }}
                                        classNames="fade"
                                    >
                                        <section className="route-section">
                                          <NavBarMultiPage pageName="home" />
                                            <Switch location={location}>
                                                <Route
                                                    path="/privacy-policy"
                                                    component={PrivacyPolicy}
                                                />
                                                <Route
                                                    path="/cookie-policy"
                                                    component={CookiePolicy}
                                                />
                                                <Route
                                                    path="/consultancy"
                                                    component={Consultancy}
                                                />
                                                <Route
                                                    path="/writing"
                                                    component={Writing}
                                                />
                                                <Route
                                                    path="/sitemap"
                                                    component={SiteMap}
                                                />
                                                <Route
                                                    path="/contact"
                                                    component={ContactUS}
                                                />
                                                <Route
                                                    path="/support"
                                                    component={ContactUS}
                                                />
                                                <Route
                                                    path="/career"
                                                    component={Career}
                                                />
                                                <Route
                                                    path="/about"
                                                    component={About}
                                                />
                                                <Route
                                                    path="/services"
                                                    component={Services}
                                                />
                                                <Route
                                                    path="/team"
                                                    component={Team}
                                                />
                                                <Route
                                                    path="/faq"
                                                    component={Faq}
                                                />
                                                <Route
                                                    path="/hire"
                                                    component={Hire}
                                                />
                                                <Route
                                                    path="/maintenance-support"
                                                    component={MaintainSupport}
                                                />
                                                <Route
                                                    path="/support-help"
                                                    component={MaintainSupport}
                                                />
                                                <Route
                                                    path="/pricing"
                                                    component={Pricing}
                                                />
                                                <Route
                                                    path="/development"
                                                    component={WebDevelopment}
                                                />
                                                <Route
                                                    path="/magento"
                                                    component={WebDevelopment}
                                                />
                                                <Route
                                                    path="/big-commerce"
                                                    component={BigCommerce}
                                                />
                                                <Route
                                                    path="/shopify"
                                                    component={Shopify}
                                                />
                                                <Route
                                                    path="/woocommerce"
                                                    component={WooCommerce}
                                                />
                                                <Route
                                                    path="/opencart"
                                                    component={Opencart}
                                                />
                                                <Route
                                                    path="/drupal"
                                                    component={Drupal}
                                                />
                                                <Route
                                                    path="/wordpress-development"
                                                    component={WordpressDevelopment}
                                                />
                                                <Route
                                                    path="/joomla"
                                                    component={Joomla}
                                                />
                                                <Route exact
                                                    path="/sharepoint"
                                                    component={Sharepoint}
                                                />
                                                <Route exact
                                                    path="/hub-spot"
                                                    component={HubSpot}
                                                />
                                                <Route exact
                                                    path="/core-php"
                                                    component={CorePhp}
                                                />
                                                <Route
                                                    path="/cakephp"
                                                    component={CakePhp}
                                                />
                                                <Route
                                                    path="/laravel"
                                                    component={Laravel}
                                                />
                                                <Route
                                                    path="/yii"
                                                    component={Yii}
                                                />
                                                <Route
                                                    path="/angular-js"
                                                    component={Angularjs}
                                                />
                                                <Route
                                                    path="/node-js"
                                                    component={Nodejs}
                                                />
                                                <Route
                                                    path="/mean-stack"
                                                    component={Meanstack}
                                                />
                                                <Route
                                                    path="/node-js"
                                                    component={Node}
                                                />
                                                <Route
                                                    path="/mongodb"
                                                    component={MongoDB}
                                                />
                                                <Route
                                                    path="/react-native"
                                                    component={ReactNative}
                                                />
                                                <Route
                                                    path="/how-it-works"
                                                    component={HowWork}
                                                />
                                                <Route
                                                    path="/social-media-optimization"
                                                    component={Marketing}
                                                />
                                                <Route
                                                    path="/marketing"
                                                    component={Marketing}
                                                />
                                                <Route
                                                    path="/pay-per-click-services"
                                                    component={ClickServices}
                                                />
                                                <Route
                                                    path="/digital-marketing-seo"
                                                    component={DigitalMarketing}
                                                />
                                                <Route
                                                    path="/conversion-rate-optimisation"
                                                    component={ConversionRate}
                                                />
                                                <Route
                                                    path="/hosting"
                                                    component={Hosting}
                                                />
                                                <Route
                                                    path="/career"
                                                    component={HomeTwo}
                                                />
                                                <Route
                                                    path="/pwa"
                                                    component={Pwa}
                                                />
                                                <Route
                                                    path="/design"
                                                    component={Design}
                                                />
                                                <Route
                                                    path="/"
                                                    exact
                                                    component={HomeTwo}
                                                />
                                                <Redirect to="/not-found" />
                                            </Switch>
                                            <Footer  />
                                        </section>
                                    </CSSTransition>
                                </TransitionGroup>
                            )}
                        />
                        <ScrollUpBtn />
                    </Page>
                </div>
            </div>
        );
    }
}

export default App;