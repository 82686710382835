import React from 'react';
import { axiosPage } from '../API';
import BriefContact from '../components/common/briefContact'
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";
import GetStartedForm from "../components/common/GetStartedForm";

class Design extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            showcaseName: "",
            showcaseDescription: "",
            showcaseImage: "",
            sectionName: "Web Design",
            sectionOneTitle: "",
            sectionOneDescription: "",
            sectionOneData:[],
            sectionTwoTitle: "",
            sectionTwoDescription: "",
            sectionTwoData:[],
            section5Services: [],
			featuresTitle: "",
			featuresDescription: "",
			featuresImage: "",
			featuresListing: [],
            showDesignBanner:false			
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`design`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const sectionTwo = response.section2;
				const features = response.features;
                this.setState({
                    showcaseName: response.title,
                    showcaseDescription: response.description,
                    showcaseImage: response.image.url,
                    showDesignBanner:true,
                    sectionOneTitle: sectionOne.title,
                    sectionOneDescription: sectionOne.description,
                    sectionOneData:sectionOne.services,
                    sectionTwoTitle: sectionTwo.title,
                    sectionTwoDescription: sectionTwo.description,
                    sectionTwoData:sectionTwo.services,
					featuresTitle: features.title,
					featuresDescription: features.description,
					featuresListing: features.listing,
					featuresImage: features.image
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
       
        const sectionOne = this.state.sectionOneData && (this.state.sectionOneData.map((value, index) => (
            <div className="col-lg-3 col-md-6 offset-lg-0" key={index}>
                <div className="service-box text-center">
                        <div className="service-box-pic">
                           {value.icon && <i className={value.icon} />} 
                           {value.image && <img src={value.image.url} alt="service" width="80" height="80"/>} 
                        </div>
                        <h6>{value.title}</h6>
                    </div>
                {/* <div className="single-features">
                    <i className={value.icon} />
                    <h3>{value.featuresName}</h3>
                </div> */}
            </div>
        )));
        const sectionTwo = this.state.sectionTwoData && (this.state.sectionTwoData.map((value, index) => (
            <div className="col-lg-3 col-md-6" key={index}>
               <div className="service-box text-center">
                        <div className="service-box-pic">
                        {value.icon && <i className={value.icon} />} 
                           {value.image ? <img src={value.image.url} alt="service" width="80" height="80"/>:''} 
                        </div>
                        <h6>{value.title}</h6>
                    </div>
            </div>
        )));
        return (
            <React.Fragment >
                 {this.state.showDesignBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
				<div className="textSection pt50 mb0">
					<div className="container">
						<div className="row">
							<div className="col-md-7 pic">
								<div className="img">
									<img src={this.state.featuresImage} alt="img" />
								</div>
							</div>
							<div className="col-md-5 info">
								<h3>{this.state.featuresTitle}</h3>
								<p>{this.state.featuresDescription}</p>
								{this.state.featuresListing ?<ul class="pull-left checkList">
									{this.state.featuresListing.map((value, index) => (
										<li><i class="icofont-ui-check"></i>{value.add_list}</li>
									))}
								</ul> : ''}
							</div>							
						</div>
					</div>
				</div>
				<section id="services" className="services-area ptb-50">
					<div className="container">
						<div className="section-title">
							<h3>{this.state.sectionOneTitle}</h3>
							<p>{this.state.sectionOneDescription}</p>
						</div>
						<div className="row">{sectionOne}</div>
					</div>
				</section>
				<div className="services-area pt-0">
					<div className="container">
						<div className="section-title">
							<h3>{this.state.sectionTwoTitle}</h3>
							<p>{this.state.sectionTwoDescription}</p>
						</div>
						<div className="row">{sectionTwo}</div>
					</div>
				</div>
				<GetStartedForm pageName="Hire" />
				{/* Testimonial: src/components/versionTwo/Testimonial */}
                <Testimonial pageName="home" />     
                {/* : ''} */}
            </React.Fragment>
        );
    }
}


export default Design;