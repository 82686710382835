import React from 'react';
import BriefContact from '../components/common/briefContact';
import { axiosPage } from '../API';
import i from 'react-icofont';
import Subscribe from '../components/common/Subscribe';
import GetStartedForm from '../components/common/GetStartedForm';
import Showcase from "../components/versionTwo/Showcase";
import Testimonial from "../components/versionTwo/Testimonial";

class Hosting extends React.Component {
    constructor(props) {
        super(props);
        // 
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            section1Image: "",
            section1Title: "",
            section1Description: "",
			section4Image: "",
            section4Title: "",
            section4Description: "",
			section5Image: "",
            section5Title: "",
            section5Description: "",
            section2Title: "",
            section2Services: [],
            showHostingBanner:false
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`hosting`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const sectionOne = response.section1;
                const section4 = response.section4;
                const section5 = response.section5;
                const sectionTwo = response.section2;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showHostingBanner:true,
                    section1Image: sectionOne.image ? sectionOne.image.url:'',
                    section1Title: sectionOne.title,
                    section1Description: sectionOne.description,
					section4Image: section4.image ? section4.image.url:'',
                    section4Title: section4.title,
                    section4Description: section4.description,
					section5Image: section5.image ? section5.image.url:'',
                    section5Title: section5.title,
                    section5Description: section5.description,
                    section2Title: sectionTwo.title,
                    section2Services: sectionTwo.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const sectionOne =
            <React.Fragment >
                <div className="textSection mt30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 pic">
                                <div className="img">
                                    <img src={this.state.section1Image} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 info">
								<h3>{this.state.section1Title}</h3>
								<p>{this.state.section1Description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
		const section4 =
            <React.Fragment >
                <div className="textSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 info">
								<h3>{this.state.section4Title}</h3>
								<p>{this.state.section4Description}</p>
                            </div>
							<div className="col-lg-6 col-md-6 pic">
                                <div className="img">
                                    <img src={this.state.section4Image} alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
		const section5 =
            <React.Fragment >
                <div className="textSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 pic">
                                <div className="img">
                                    <img src={this.state.section5Image} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 info">
								<h3>{this.state.section5Title}</h3>
								<p>{this.state.section5Description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        const servicedata = this.state.section2Services && (this.state.section2Services.map((service, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <div className="service-box text-center">
                    <div className="service-box-pic">
                        <img src={service.image && service.image.url} alt="service" width="80" height="80"/>
                    </div>
                    <h6>{service.title}</h6>
                </div>
            </div>
        )));

        return (
            <React.Fragment >
                {this.state.showHostingBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                {sectionOne}
                {section4}
                {section5}
                {/* {sectionTwo} */}
                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area"}>
                    <div className="container">
                        <div className="section-title">
                            {/* <span>{this.state.sectionName}</span> */}
                            <h3>Services Magento Web Hosting Platform</h3>
							<p>We Provide You 24/7 Hosting, so that your business can always stand out & remain at the top.</p>
                            {/* <p>{this.props.sectionDescription}</p> */}
                        </div>
                        <div className="row">{servicedata}</div>
                    </div>
                </section>
                <GetStartedForm description="Get in touch to know how we can help you grow your business." />
				<Testimonial pageName="home" />   
            </React.Fragment>
        );
    }
}

export default Hosting;