import React, { Component } from "react";
import { axiosPage } from '../../API';

class SupportServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            sectionName: "",
            sectionTitle: "",
            sectionDescription: "",
            servicesData: []
        };
        this.getData();
    }

    getData() {
        axiosPage
            .get(`support_your_growth`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                this.setState({
                    sectionName: response.heading,
                    sectionTitle: response.title,
                    servicesData: response.services,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    render() {
        //Service loop start
        const servicedata = this.state.servicesData && (this.state.servicesData.map((service, index) => (
            <div className="col-lg-4 col-md-6" key={index}>
                    <div className="service">
                        <div className="services service-image ">
                            <img className="service-image" src={service.image.url} alt="serviceimage"/>
                        </div>
                        <div className="service-content mb-50">
                            <h6 className="uppercase title">{service.title}</h6>
                            {service.content ? <p>{service.content}</p> : ""}</div>
                    </div>
            </div>
        )));
        //Service loop END
        return (
            <React.Fragment>
                <section id="services" className={this.props.pageName === "home" ? 'mt-30' : "services-area  ptb-50 "}>
                    <div className="container">
                        <div className="section-title">
                            <span>{this.state.sectionName}</span>
                            <h3>{this.state.sectionTitle}</h3>

                        </div>
                        {this.state.pageName === "home" ? '' : <div className="row">{servicedata}</div>}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default SupportServices;
