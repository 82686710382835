import React from 'react';
import { axiosPage } from '../API';
import Subscribe from "../components/common/Subscribe";
import Showcase from "../components/versionTwo/Showcase";

class CookiePolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            showcaseDescription: '',
            showcaseImage: '',
            showcaseName: '',
            description: '',
            content: "",
            showBanner: false,
            last_section:{
                title:'',
                content1:'',
                content2:'',
                title_list_1:'',
                title_list_2:'',
                listing1:'',
                listing2:'',
            }
            ,
            formData: {
                naame: "",
                email: "",
                subject: "",
                message: "",
            },
            successMsg: ""
        };
        this.getData();
        // this.handleChange = this.handleChange.bind(this);
    }
    getData() {
        axiosPage
            .get(`cookie_policy`)
            .then(result => {
                console.log('result:', result.data)
                const response = result.data;
                const lastsection = response.last_section;
                this.setState({
                    data: response,
                    showcaseName: response.title,
                    showcaseImage: response.image ? response.image.url : '',
                    showcaseDescription: response.description,
                    showBanner: true,
                    last_section: response.last_section ? response.last_section : "",
                    title:lastsection.title,
                    content1:lastsection.content,
                    content2:lastsection.content_2,
                    title_list_1:lastsection.title_list_1,
                    title_list_2:lastsection.title_list_2,
                    listing1:lastsection.listing1,
                    listing2:lastsection.listing_2,
                    content: response.sections,
                },
                );
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const data = this.state.content && (this.state.content.map((service, index) => (
            <div className="section-title about-text" key={index}>
                <h3>{service.title}</h3>
                <p>{service.list_title && service.list_title}</p>
                <ul>
                    {service.listing && service.listing.map((list, i) =>
                        <li className="listing" key={i}>
                            <i className="icofont-ui-check" />
                            {list.add_list}
                        </li>)}
                </ul>
                <p>{service.description}</p>
            </div>
        )));
        return (
            <React.Fragment >
                {this.state.showBanner &&
                    (<Showcase
                        showcaseImage={this.state.showcaseImage}
                        showcaseName={this.state.showcaseName}
                        showcaseDescription={this.state.showcaseDescription} />)
                }
                <section id="services" className='mt-30'>
                    <div className="container">
                        {data}
                        {/* <div className="row">{data}</div> */}
                    </div>
                </section>
                <section id="services" className='mt-30'>
                    <div className="container">
                        <div className="section-title about-text" >
                            <h3>{this.state.last_section.title && this.state.last_section.title}</h3>
                            <p>{this.state.last_section.title_list_1}</p>
                            <ul>
                                {this.state.last_section.listing_1 && this.state.last_section.listing_1.map((list, i) =>
                                    <li className="listing" key={i}>
                                        <i className="icofont-ui-check" />
                                        {list.listing}
                                    </li>)}
                            </ul>
                            <p>{this.state.last_section.content1}</p>
                            <p>{this.state.last_section.title_list_2}</p>
                            <ul>
                                {this.state.last_section.listing_2 && this.state.last_section.listing_2.map((list, i) =>
                                    <li className="listing" key={i}>
                                        <i className="icofont-ui-check" />
                                        {list.listing}
                                    </li>)}
                            </ul>
                            <p>{this.state.last_section.content2}</p>
                        </div>
                    </div>
                </section>
                <Subscribe />
            </React.Fragment>
        );
    }
}

export default CookiePolicy;